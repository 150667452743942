import * as HttpUtils from '../utils/http-utils';
import {getJSONP} from '../utils/http-utils';
import Endpoints from "../config/endpoints";
import {getFromCacheOrDefault} from "../config/app-cache";

export const getAppConfig = (appId) => HttpUtils.getData(Endpoints.GET_APP_CONFIG(appId));

export const getAppLayout = (appId) => HttpUtils.getData(Endpoints.GET_APP_LAYOUT(appId));

export const getAppStyleClasses = (appId) => HttpUtils.getData(Endpoints.GET_APP_STYLE_CLASSES(appId));

export const loadAllActionScripts = (appId) => {
    return new Promise((resolve, reject) => {
        const callbackId = `a${appId}_scriptActions`;
        window[callbackId] = function (object) {
            delete window[callbackId];
            return resolve(object);
        };
        return getJSONP(Endpoints.LOAD_ALL_SCRIPT_ACTIONS_FOR_APP(appId), callbackId);
    });
};

export const runScriptAction = (actionId, parameters, formValueForElement) => {
    const scriptActions = getFromCacheOrDefault("scriptActions");
    const script = scriptActions && scriptActions[actionId];
    if(script && typeof(script) === "function") {
        return script(parameters, formValueForElement);
    }
};