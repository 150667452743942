import * as R from 'ramda';
import {getCachedAppId} from "./app-cache";

const uri = window.location.href.replace(window.location.hash, "");

// const FLOW_HOST = uri + (uri.endsWith("/") ? "" : "/") + 'api';

const FLOW_HOST = process.env.REACT_APP_API_HOST || (uri + (uri.endsWith("/") ? "" : "/") + 'flow/api');

const APP_HOST = R.compose(
    R.join("/"),
    R.dropLast(2),
    R.split('/'),
    (s) => R.endsWith("/", s) ? R.dropLast(1, s) : s,
    R.head,
    R.split("#"))(window.location.href);

const CDN_HOST = window.cdnHost || "/";

const Endpoints = {

    VALIDATE_USER_NAME: FLOW_HOST + `/auth/user_name`,
    SIGN_IN: FLOW_HOST + '/auth/login',
    SIGN_OUT: FLOW_HOST + '/me/logout',
    GET_PROFILE: FLOW_HOST + '/me',
    UPDATE_PASSWORD: FLOW_HOST + '/me/password',
    FORGOT_PASSWORD: FLOW_HOST + '/auth/forgot/password',
    RESET_PASSWORD: FLOW_HOST + '/auth/reset/password',

    VERIFY_EMAIL_TOKEN: (token) => FLOW_HOST + `/auth/token/${token}`,

    VALIDATE_RESET_PASSWORD_TOKEN: FLOW_HOST + `/auth/forgot/password/validate`,

    REGISTER_USER: FLOW_HOST + `/auth/user/register`,
    VALIDATE_USER_REGISTRATION: FLOW_HOST + `/auth/user/registration/info`,

    VERIFY_USER_NAME: (userName) => FLOW_HOST + `/auth/login/user/${userName}/verify`,
    GET_USER_PROFILES_BY_IDS: (userNames) => FLOW_HOST + `/users/by/user_name?${R.join("&", R.map(un => `userName=${un}`, userNames))}`,
    GET_USER_ROLES: FLOW_HOST + '/users/roles',

    GET_APP_CONFIG: (appId) => APP_HOST + `/apps/${appId}/config`,
    GET_APP_LAYOUT: (appId) => APP_HOST + `/apps/${appId}/layout`,
    GET_APP_STYLE_CLASSES: (appId) => APP_HOST + `/apps/${appId}/style_classes`,

    GET_USER_PRINCIPAL: (appId) => APP_HOST + `/apps/${appId}/principal`,

    RUN_OPERATION: (appId, operationId) => APP_HOST + `/apps/${appId}/operations/${operationId}/execute`,

    LOAD_SCRIPT_ACTION_FOR_ACTION: (appId, pageId, actionId) => APP_HOST + `/apps/${appId}/pages/${pageId}/actions/script/${actionId}`,

    LOAD_ALL_SCRIPT_ACTIONS_FOR_APP: (appId) => APP_HOST + `/apps/${appId}/pages/actions/script`,

    APP_ASSET_DOWNLOAD_URL: (assetId) => APP_HOST + `/apps/${getCachedAppId()}/assets/${assetId}/download`,

    CDN: CDN_HOST,
    getCDNUrl: (resource) => {
        return process.env.REACT_APP_CDN_HOST + resource;
    }
};

export default Endpoints;