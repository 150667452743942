import * as R from 'ramda';
import {getSession} from "../dao/user-profile-dao";

const cache = {};

export const setInCache = (key, value) => {
    cache[key] = value;
    return value;
};

export const getFromCache = (key) => cache[key];

export const getFromCacheOrDefault = (key, defaultValue) => getFromCache(key) || defaultValue;

export const getCachedAppId = () => cache.appId;

export const getCachedAppInfo = () => cache.appInfo;

export const getCachedAppLayout = () => cache.appLayout;

export const getPageById = (pageId) => R.find(p => p._id === pageId, getCachedAppLayout() || []);

export const getDefaultPage = () => {
    const app = getCachedAppInfo();
    return getPageById(app.defaultPageId);
};

export const getPageContext = () => {
    return {
        app: getCachedAppInfo(),
        principal: getSession()
    };
};

export const getDefaultPageRoute = () => getDefaultPage().routePath;

export const isValidRoute = (route) => R.find(p => p.routePath === route, getCachedAppLayout());

export const getLoginPage = () => {
    const app = getCachedAppInfo();
    return getPageById(app.loginPageId);
};

export const getSignInPageRoute = () => {
    const page = getLoginPage();
    return page.routePath;
};

export const getLogoutOperationId = () => getCachedAppInfo().logoutOperationId;

export const getLoginOperationId = () => getCachedAppInfo().loginOperationId;

export const getPageByRoute = (route) => {
    const page = R.find(p => p.routePath === route, getCachedAppLayout());
    return page || getDefaultPage();
};

export const getRouteByPageId = (pageId) => getPageById(pageId).routePath;

export const isSessionRequiredForApp = () => getCachedAppInfo().hasSession;

// export const isSessionRequiredForPage = (pageId) => {
//     const hasSession = getCachedAppInfo().hasSession;
//     const page = R.find(page => page._id === pageId, getCachedAppLayout() || []);
//
// };

export default cache;