
const R = require('ramda');

export const getInputSelection = (el) => {
    let start = 0, end = 0, normalizedValue, range,
        textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
        start = el.selectionStart;
        end = el.selectionEnd;
    } else {
        range = document.selection.createRange();

        if (range && range.parentElement() === el) {
            len = el.value.length;
            normalizedValue = el.value.replace(/\r\n/g, "\n");

            // Create a working TextRange that lives only in the input
            textInputRange = el.createTextRange();
            textInputRange.moveToBookmark(range.getBookmark());

            // Check if the start and end of the selection are at the very end
            // of the input, since moveStart/moveEnd doesn't return what we want
            // in those cases
            endRange = el.createTextRange();
            endRange.collapse(false);

            if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                start = end = len;
            } else {
                start = -textInputRange.moveStart("character", -len);
                start += normalizedValue.slice(0, start).split("\n").length - 1;

                if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                    end = len;
                } else {
                    end = -textInputRange.moveEnd("character", -len);
                    end += normalizedValue.slice(0, end).split("\n").length - 1;
                }
            }
        }
    }

    return {
        start,
        end
    };
};

export const getCursorPositionInInput = (el) => getInputSelection(el).end;

export const prepareExpressionForString = (str, position, key) => {
    const r = getExpressionRangeForPosition(str, position);
    if(r) {
        console.log("range: " + JSON.stringify(r));
        const [exprStartChar, exprEndChar] = ["{{{", "}}}"]; //str.substring(r[0]).startsWith("{{{") ? ["{{{", "}}}"] : ["{{", "}}"];

        const expr = trimExpressionCharsFromExpression(getExpressionForPosition(str, position));
        const paths = expr.substring(0, position - r[0]).split(".");
        const newPath = R.join(".", R.append(key, R.dropLast(1, paths)));

        return str.substring(0, r[0]) + `${exprStartChar}${newPath}${exprEndChar}` + (r[1] >= 0 ? str.substring(r[1]) : "");
    }
};

// export const isInExpression = (str, position) => {
//     // const left = str.substring(0, position);
//     // const start = left.lastIndexOf("{{");
//     // if(start < 0) return false;
//     // return left.substring(start + 1).indexOf("}}") < 0;
//     return !!getExpressionRangeForPosition(str, position);
// };

export const getExpressionForPosition = (str, position) => {
    const r = getExpressionRangeForPosition(str, position);
    return r && str.substring(r[0], r[1]);
};

export const trimExpressionCharsFromExpression = (expr) => expr.replace("{{{", "").replace("{{", "").replace("}}}", "").replace("}}", "");

export const getExpressionRangeForPosition = (str, position) => {
    const left = str.substring(0, position);
    const right = str.substring(position);

    let start = Math.max(left.lastIndexOf("{{{"), left.lastIndexOf("{{"));
    if(start < 0 || start < left.lastIndexOf("}}")) return null;
    start = left.charAt(start-1) === "{" ? start - 1 : start;

    const se = right.indexOf("}}");
    const le = right.indexOf("}}}");
    let end = se < le ? se + 2 : le + 3;
    // end = end < 0 ? right.indexOf("}}") + 2 : end + 3;
    if(end < 0 || end < right.lastIndexOf("}}")) {
        const e = str.substring(end).indexOf(" ");
        return [start, start + e];
    }
    return [start, end + position];
};
