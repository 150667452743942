import * as R from "ramda";
import {
    getCachedAppId,
    getLoginOperationId,
    getLogoutOperationId,
    getPageById,
    getRouteByPageId,
    getDefaultPage
} from "../config/app-cache";
import Endpoints from "../config/endpoints";
import ERRORS from "../config/errors";
import * as UserProfileDAO from '../dao/user-profile-dao';
import { getRedirectUrl, renderPage, uploadFileByChunks } from "../utils/app-utils";
import { getLocale, getLocalTimeZone } from "../utils/date-utils";
import { gotoRoute, isOnline } from "../utils/generic-utils";
import * as HttpUtils from '../utils/http-utils';
import { getDownloadFileNameFromHeaders, getJSONP, HTTP_METHOD } from '../utils/http-utils';
import { autoLogin } from "./user-profile-service";

export const RESPONSE_TYPES = {
    DATA: "DATA",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    PAGE: "PAGE",
    DOWNLOAD: "DOWNLOAD",
    INVALID_SESSION: "INVALID_SESSION",
    URL_REDIRECT: "URL_REDIRECT"
};

export const UPLOAD_FAILURE_TYPE = {
    SERVER_ERROR: "SERVER_ERROR",
    GATEWAY_ERROR: "GATEWAY_ERROR",
    NETWORK_ERROR: "NETWORK_ERROR"
};

const canFileRetryForUpload = (file) => {
    if(!file.failureType) return true;
    if(file.failureType === UPLOAD_FAILURE_TYPE.NETWORK_ERROR && file.allowUserRetryForNetworkFailures) return true;
    if(file.failureType === UPLOAD_FAILURE_TYPE.GATEWAY_ERROR && file.allowUserRetryForNetworkFailures) return true;
    return file.failureType === UPLOAD_FAILURE_TYPE.SERVER_ERROR && file.allowUserRetryForServerFailures;
};

export const processChunkedFiles = (operationId, input, chunkedUploadFiles, progressCallback, uploadErrorCallback) => {
    if(R.isEmpty(chunkedUploadFiles || {})) return Promise.resolve();

    const uploader = (key) => {
        return (file, data) => {
            const newInput = R.mergeLeft({[key]: file}, input);
            return runOperation(operationId, newInput, {[key]: data})
                .then(result => {
                    console.log("result came: ", result);
                    if(result.type === RESPONSE_TYPES.ERROR) {
                        throw result;
                    } else if(progressCallback) {
                        return progressCallback(file.ref, file.lastChunk ? 100 : file.contentPercentage, file.contentUploaded);
                    }
                });
        };
    };

    return R.reduce((acc, key) => {
        return acc.then(result => {
            const keyFiles = Array.isArray(chunkedUploadFiles[key]) ? chunkedUploadFiles[key] : [chunkedUploadFiles[key]];
            result[key] = {success: [], failed: []};
            return R.reduce((acc2, file) => {
                return acc2.then(status => {
                    file.percentageCompleted = file.percentageCompleted || file.contentPercentage || 0;
                    file.contentUploaded = file.contentUploaded || 0;
                    const progressJob = progressCallback && canFileRetryForUpload(file) ? progressCallback(file.ref, file.percentageCompleted, file.contentUploaded) : Promise.resolve();
                    return progressJob
                        .then(() => {
                            if(file.size <= 0) throw {code: "FILE_EMPTY", description: `${file.name} is empty file`};

                            if(file.percentageCompleted >= 100) return;
                            if(canFileRetryForUpload(file)) return uploadFileByChunks({file, uploader: uploader(key)});
                            else throw {code: "NOT_RETRYABLE"}
                        })
                        .then(() => status.success.push(convertFileToJSON(file)))
                        .catch(error => {
                            if(error && error.code === "NOT_RETRYABLE") return status.failed.push(convertFileToJSON(file));

                            console.log("response status: ", error.status, error.statusText);
                            let throwError = true;
                            let failureType = UPLOAD_FAILURE_TYPE.SERVER_ERROR;
                            if(error.type === RESPONSE_TYPES.ERROR && (!R.isEmpty(error.formErrors || []) || (!R.isEmpty(error.fieldErrors || {})))) {
                                throwError = false;
                                if(!R.isEmpty(error.formErrors)) error = error.formErrors[0];
                                else if(!R.isEmpty(error.fieldErrors)) error = error.fieldErrors[0];
                            } else if(!R.isNil(error.readyState)) {
                                console.log("response status: ", error.status);
                                failureType = UPLOAD_FAILURE_TYPE.GATEWAY_ERROR;
                                error = {
                                    code: "BAD_GATEWAY",
                                    description: "Service temporarily unavailable. Please retry."
                                };
                            }
                            const hasInternet = isOnline();
                            if(!hasInternet) {
                                failureType = UPLOAD_FAILURE_TYPE.NETWORK_ERROR;
                                error = error || {};
                                error.code = "INTERNET_ERROR";
                            } else {
                                error.code = error.code || error.name || error.statusText;
                                error.description = error.description || error.statusText;
                            }
                            console.error("Ignoring file since error while upload of file: ", file, error);
                            status.failed.push(convertFileToJSON(file));
                            uploadErrorCallback && uploadErrorCallback(file.ref, error, failureType);
                            // if(hasInternet) return job;
                            if(throwError) throw error;
                        })
                        .then(() => status);
                });
            }, Promise.resolve(result[key]), keyFiles)
                .then(() => result);
        });
    }, Promise.resolve({}), R.keys(chunkedUploadFiles));
};

const convertFileToJSON = (file) => {
    return {
        ref: file.ref,
        name: file.name,
        contentUploaded: file.contentUploaded,
        lastModified: file.lastModified,
        percentageCompleted: file.percentageCompleted,
        size: file.size,
        type: file.type
    };
};

export const runDownloadOperation = (operationId, input, files, method = HTTP_METHOD.POST) => {
    const appId = getCachedAppId();
    const url = Endpoints.RUN_OPERATION(appId, operationId);

    const body = new FormData();

    body.append("input", typeof(input) === "object" ? JSON.stringify(input) : input);

    R.forEach(fid => {
        files[fid] && body.append(fid, files[fid], files[fid].name);
    }, R.keys(files || {}));

    const headers = {
        "client-time-zone": getLocalTimeZone(),
        "client-locale": getLocale()
    };
    

    return fetch(url, {
        method,
        body,
        headers
    })
        .then(response => {
            const fileName = getDownloadFileNameFromHeaders(response.headers);
            return response.blob()
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                    window.URL.revokeObjectURL(url);
                })
        });
};

export const runOperation = (operationId, input, files, chunkedUploadFiles, progressCallback, uploadErrorCallback) => {
    const appId = getCachedAppId();
    console.log("running operation: ", operationId, " input: ", JSON.stringify(input), " files: ", files);
    const logoutOperation = getLogoutOperationId() === operationId;
    const loginOperation = getLoginOperationId() === operationId;

    const process = (!files || R.isEmpty(files)) && !R.isEmpty(chunkedUploadFiles || {});

    if(process) {
        return processChunkedFiles(operationId, input, chunkedUploadFiles, progressCallback, uploadErrorCallback)
            .then(uploadResult => runOperation(operationId, R.mergeLeft({operationComplete: true, uploadResult}, input)));
    } else {
        const headers = {
            "client-time-zone": getLocalTimeZone(),
            "client-locale": getLocale()
        };
        return HttpUtils.multipart(Endpoints.RUN_OPERATION(appId, operationId), {input}, files, headers)
            .then(result => {
                if(result) {
                    if(logoutOperation && result.type !== RESPONSE_TYPES.ERROR) {
                        if(result.type === RESPONSE_TYPES.URL_REDIRECT) {
                            if(window.location.href === result.url) window.location.reload();
                            else window.location.href = result.url;
                            throw {code: ERRORS.PAGE_TRANSITION};
                        }
                        UserProfileDAO.removeUserSession();
                    }

                    const postLogin = loginOperation && result.type !== RESPONSE_TYPES.ERROR ? autoLogin() : Promise.resolve();

                    return postLogin
                        .then(_ => {
                            if(result.type === RESPONSE_TYPES.INVALID_SESSION) {
                                UserProfileDAO.logout();
                                // EventsHandler.push({name: Events.GO_TO_LOGIN});
                                throw new Error("Invalid Session");
                            } else if(result.type === RESPONSE_TYPES.URL_REDIRECT) {
                                if(window.location.href === result.url) window.location.reload();
                                else window.location.href = result.url;
                                throw {code: ERRORS.PAGE_TRANSITION};
                            } else {

                                if(loginOperation && result.type !== RESPONSE_TYPES.ERROR) {
                                    const rd = getRedirectUrl();
                                    if(rd) {
                                        window.location.href = rd;
                                        throw {code: ERRORS.PAGE_TRANSITION};
                                    } else {
                                        renderPage(getDefaultPage());
                                    }
                                }
                                if(result.type === RESPONSE_TYPES.PAGE) {
                                    const page = getPageById(result.page.id);
                                    if(result.page.changeRoute === "true" || result.page.changeRoute === true) gotoRoute(getRouteByPageId(result.page.id), result.page.props, result.page.deepLink, loginOperation || logoutOperation);
                                    else renderPage(page, result.page.props);
                                    throw {code: ERRORS.PAGE_TRANSITION};
                                }
                            }
                            return result;
                        });
                }
            })
            .then((result) => {
                if(!R.isEmpty(chunkedUploadFiles || {})) {
                    return processChunkedFiles(operationId, input, chunkedUploadFiles, progressCallback, uploadErrorCallback)
                        .then(uploadResult => runOperation(operationId, R.mergeLeft({operationComplete: true, uploadResult}, input)));
                }
                return result;
            });
    }
};


export const generateFileDownloadLinkForOperation = (operationId) => Endpoints.RUN_OPERATION(getCachedAppId(), operationId);

export const loadActionScriptHandler = (appId, pageId, actionId) => {
    return new Promise((resolve, reject) => {
        const callbackId = `a${appId}_p${pageId}_a${actionId}`;
        window[callbackId] = function (object) {
            delete window[callbackId];
            return resolve(object);
        };
        return getJSONP(Endpoints.LOAD_SCRIPT_ACTION_FOR_ACTION(appId, pageId, actionId), callbackId);
    });
};