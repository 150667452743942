import React from "react";
import * as R from 'ramda';
import {
    generateFileDownloadLinkForOperation,
    runDownloadOperation,
    runOperation,
    UPLOAD_FAILURE_TYPE
} from "../../service/op-service";
import {goToPage, openUrl} from "../../utils/ui-utils";
import {
    getAttrValueForEventFromClosest,
    getAttrValueFromClosest,
    getClosestForEventWithAttribute,
    getValueForPathOrDefault,
    mapIndexed,
    reduceIndexed,
    removePathForObject,
    serializeFormV2,
    setValueForPathOrDefault,
    validateFormV2
} from "ui-comps/utils/generic-utils";
import {compileLayoutWithParser, EVAL_AT} from "../../utils/template-utils";
import {goBack, newUUID} from "../../utils/generic-utils";
import {getPageContext} from "../../config/app-cache";
import $ from 'jquery';
import ERRORS from "../../config/errors";
import DynamicComponentView, {ACTION_TYPE, REPEATER_TYPE, VALUE_FORMATTER} from "./dynamic-component-view";
import {formatDateWithFormat} from "ui-comps/src/utils/date-utils";
import {runScriptAction} from "../../service/app-service";

const OPERATION_RESULT_TYPE = {
    DATA: "DATA",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    PAGE: "PAGE",
    DOWNLOAD: "DOWNLOAD"
};

export default class DynamicComponent extends DynamicComponentView {

    loadData() {
        super.loadData();
        this.state.dataLoaded = false;
        const ds = this.getDataSources();
        const layoutConfig = R.clone(this.props.page.layoutConfig || {});

        // loadActionScriptHandler(this.props.page.appId, this.props.page._id, "123")
        //     .then((result) => {
        //         debugger;
        //         console.log("action script result: ", result);
        //         console.log("output: ", result.run({a: 10, b: 12}));
        //     })
        //     .catch(err => {
        //         debugger;
        //         console.error("error: ", err);
        //     });

        let data = {
            pageProps: this.props.pageProps,
            _context: getPageContext()
        };
        if (ds && !R.isEmpty(ds)) {
            return R.reduce((acc, ds) => {

                return acc
                    .then(() => {

                        const pageData = this.state.pageData || {};
                        ds = compileLayoutWithParser(ds, R.mergeLeft({pageData}, R.mergeLeft(data, pageData)));

                        if(ds.type === "SCRIPT") {

                            const input = R.reduce((acc, kv) => R.assoc(kv.key, kv.value, acc), {}, ds.input || []);
                            runScriptAction(ds.id, input);

                            return Promise.resolve();
                        }

                        const key = ds.key;
                        const operationId = ds.operationId;
                        // TODO handle calls based on input http method

                        console.log("opInput: ", JSON.stringify(ds.input));

                        return runOperation(operationId, ds.input)
                            .then(result => this.setDataFromSource(key, result.data));
                    });

            }, Promise.resolve(), ds)
                .then(() => {
                    const pageData = this.state.pageData || {};
                    data = R.mergeLeft(R.mergeLeft({pageData}, pageData), data);
                    // console.log("data for parser: ", JSON.stringify(data));
                    return this.setState({
                        dataLoaded: true,
                        layoutConfig: compileLayoutWithParser(layoutConfig, data),
                        compiledData: data,
                        pageProps: this.props.pageProps
                    });
                })
                .catch(err => {
                    if(!err || err.code !== ERRORS.PAGE_TRANSITION) throw err;
                });
        } else {
            this.state = R.mergeLeft({
                dataLoaded: true,
                layoutConfig: compileLayoutWithParser(layoutConfig, data),
                compiledData: data,
                pageProps: this.props.pageProps,
                pageData: {}
            }, this.state || {});
        }
    }

    setDataFromSource = (key, data) => {
        const pageData = R.assoc(key, data, this.state.pageData || {});
        return this.setState({pageData});
    };

    toggleButtonActionState = (buttonId, running) => {
        let runningActions = this.state.runningActions || {};
        runningActions = running ? R.assoc(buttonId, true, runningActions) : R.dissoc(buttonId, runningActions);
        return this.setState({runningActions});
    };

    compileActionElement = (e, element, applyValidations = true, data) => {

        let $form = getClosestForEventWithAttribute(e, 'data-form');
        $form = $form.length > 0 ? $form : this.$container;

        if(applyValidations) {
            const clientErrors = R.mergeLeft(validateFormV2($form), this.validateLocalFiles($form));

            if(!R.isEmpty(clientErrors)) {
                return this.setState({clientErrors, errors: null})
                    .then(() => ({proceed: false}));
            }
        }

        const pageForm = serializeFormV2($form);

        const formValueForElement = element.key && getValueForPathOrDefault(pageForm, element.key);

        const layout = R.clone(element);

        const newData = R.mergeLeft({pageForm}, data);

        return Promise.resolve({formValueForElement, proceed: true, element: compileLayoutWithParser(layout, newData, EVAL_AT.ACTION_SUBMIT)});
    };

    hideElement = (element) => element.hide;

    progressCallback = (fileRef, percentageCompleted, contentUploaded) => {
        const file = R.find(file => file.ref === fileRef, R.flatten(R.values(this.state.localFiles)));
        if(!file) {
            console.warn(fileRef, "is not found in state.");
            return Promise.resolve();
        }
        // if(file.uploadFailed || R.isNil(file.percentageCompleted) || file.percentageCompleted !== percentageCompleted) {
            delete file.uploadError;
            delete file.uploadFailed;
            file.percentageCompleted = percentageCompleted;
            file.contentUploaded = contentUploaded;
            return this.setState({}, true);
        // }
        // return Promise.resolve();
    };

    uploadErrorCallback = (fileRef, error, failureType) => {

        if(error) {
            const key = R.find(key => R.any(file => file.ref === fileRef, this.state.localFiles[key]), R.keys(this.state.localFiles || {}));
            if(key) {
                const file = R.find(file => file.ref === fileRef, R.flatten(R.values(this.state.localFiles)));
                if(!file) {
                    console.warn(fileRef, "is not found in state.");
                    return;
                }
                file.uploadError = error;
                file.uploadFailed = true;
                file.failureType = failureType;

                if(error.code === "INTERNET_ERROR") error.description = "Problem with network connectivity detected. Please retry.";

                let showError = false;

                if(failureType === UPLOAD_FAILURE_TYPE.NETWORK_ERROR && file.allowUserRetryForNetworkFailures) {
                    showError = true;
                } else if(failureType === UPLOAD_FAILURE_TYPE.GATEWAY_ERROR && file.allowUserRetryForNetworkFailures) {
                    showError = true;
                } else if(failureType === UPLOAD_FAILURE_TYPE.SERVER_ERROR && file.allowUserRetryForServerFailures) {
                    showError = true;
                }

                let clientErrors = this.state.clientErrors || {};
                if(showError) {
                    const errors = R.append(error, clientErrors[key] || []);
                    clientErrors = R.assoc(key, errors, clientErrors);
                }
                const runningActions = showError ? {} : this.state.runningActions;
                return this.setState({clientErrors, runningActions, formSubmitted: !showError})
                    .then(() => {
                        throw {code: ERRORS.PAGE_SUBMIT_ERRORS};
                    });
            }
        }

        return Promise.resolve();
    };

    validateLocalFiles = ($form) => {
        const errors = {};
        const layout = this.getLayout();
        $form.find("input[type='file'][data-file-type='LOCAL']").each((index, elem) => {
            const $elem = $(elem);
            const elementPath = getAttrValueFromClosest($elem, 'data-elem-path');
            let element = getValueForPathOrDefault(layout, elementPath);
            element = this.compileElement(element, this.getCompiledData());
            const key = element.key;
            const files = this.state.localFiles[key] || [];
            const errorsForKey = [];
            if(element.required) {
                if(R.isEmpty(files)) errorsForKey.push("INVALID_FORMAT");
            }
            if(element.maxNoOfFiles) {
                if(element.maxNoOfFiles < R.length(files)) errorsForKey.push("COUNT_LIMIT");
            }
            if(element.maxFileSize) {
                if(R.any(file => element.maxFileSize * 1024 * 1024 < file.size, files)) errorsForKey.push("SIZE_LIMIT");
            }
            if(!R.isEmpty(errorsForKey)) errors[key] = errorsForKey;
        });
        return errors;
    };

    getValueForElement = (element, elementPath) => {
        if(element.formatter && element.formatter.type === VALUE_FORMATTER.DATE) {
            return formatDateWithFormat(element.value, element.formatter.format);
        }
        return element.value || "";
    };

    runActionsForElement = (e, element, actionsPath, data) => {
        const getActions = (element, actionsPath) => {
            const actions = getValueForPathOrDefault(element, actionsPath, []);
            return Array.isArray(actions) ? actions : [actions];
        };

        const actions = getActions(element, actionsPath);

        return reduceIndexed((acc, action, index) => {
            return acc.then(() => {

                data = R.mergeLeft(this.getCompiledData(), data);

                if(action.type === ACTION_TYPE.SCRIPT) {

                    return this.compileActionElement(e, element, false, data)
                        .then(result => {
                            const compiledAction = getActions(result.element, actionsPath)[index];
                            const input = R.reduce((acc, kv) => R.assoc(kv.key, kv.value, acc), {}, compiledAction.input || []);
                            const refreshView = runScriptAction(compiledAction.id, input, result.formValueForElement);
                            return this.setState({}, refreshView);
                        });

                } else if(action.type === ACTION_TYPE.SET_PAGE_STATE) {

                    return this.compileActionElement(e, element, true, data)
                        .then(result => {
                            const compiledAction = getActions(result.element, actionsPath)[index];

                            const keyValues = compiledAction.input || [];

                            if(!R.isEmpty(keyValues)) {
                                R.forEach((keyValue) => setValueForPathOrDefault(this.state, keyValue.key, keyValue.value), keyValues);
                                return this.setState({}, true);
                            }
                        });

                } else if(action.type === ACTION_TYPE.UNSET_PAGE_STATE) {

                    return this.compileActionElement(e, element, true, data)
                        .then(result => {
                            const compiledAction = getActions(result.element, actionsPath)[index];

                            const keys = compiledAction.input || [];

                            if (!R.isEmpty(keys)) {
                                R.forEach((key) => {
                                    removePathForObject(this.state, key);
                                }, keys);

                                return this.setState({}, true);
                            }
                        });

                } else if(action.type === ACTION_TYPE.SYNC_STATE) {
                    return this.setState({}, true);
                } else if (action.type === ACTION_TYPE.SUBMIT_FORM) {

                    let $form = getClosestForEventWithAttribute(e, 'data-form');
                    $form = $form[0] ? $form : this.$container.find('[data-form]');

                    const showProgress = $form.attr('data-show-progress') === "true";

                    const clientErrors = R.mergeLeft(validateFormV2($form), this.validateLocalFiles($form));

                    if(!R.isEmpty(clientErrors)) {
                        return this.setState({clientErrors, errors: null})
                            .then(() => {
                                throw {code: ERRORS.PAGE_SUBMIT_ERRORS};
                            });
                    }

                    const formData = serializeFormV2($form);

                    const files = {};

                    $form.find("input[type='file']").each((index, elem) => {
                        if(elem.getAttribute("data-file-type") !== "LOCAL") {

                            const key = elem.getAttribute("data-key");
                            const elemPath = elem.getAttribute("data-elem-path");
                            const layout = this.getLayout();
                            const element = getValueForPathOrDefault(layout, elemPath);

                            removePathForObject(formData, key);

                            if (elem.files.length > 0) {
                                if (element.allowMultiple) {
                                    mapIndexed((f, index) => {
                                        const k = `${key}.${index}`;
                                        files[k] = f;
                                    }, elem.files || []);
                                } else {
                                    files[key] = elem.files[0];
                                }
                            }
                        }
                    });

                    let chunkedUploadFiles = {};

                    R.forEach(key => {
                        if(!this.state.chunkedUpload[key]) {
                            const keyFiles = this.state.localFiles[key];
                            if(Array.isArray(keyFiles)) {
                                mapIndexed((file, index) => {
                                    files[`${key}.${index}`] = file;
                                }, keyFiles);
                            } else {
                                files[key] = keyFiles;
                            }
                        } else chunkedUploadFiles[key] = this.state.localFiles[key];
                    }, R.keys(this.state.localFiles));

                    const progressCallback = showProgress ? this.progressCallback : null;

                    return this.setState({clientErrors: null, errors: null, formSubmitted: true})
                        .then(() => {
                            if(R.isEmpty(action.operation.input || {})) {
                                return runOperation(action.operation.id, formData, files, chunkedUploadFiles, progressCallback, this.uploadErrorCallback);
                            }
                            return this.compileActionElement(e, element, false, data)
                                .then(result => {
                                    const compiledAction = getActions(result.element, actionsPath)[index];
                                    return runOperation(compiledAction.operation.id, R.mergeLeft(compiledAction.operation.input || {}, formData), files, chunkedUploadFiles, progressCallback, this.uploadErrorCallback)
                                });
                        })
                        .then(result => {
                            if(result) {
                                if(result.type === OPERATION_RESULT_TYPE.ERROR) {
                                    const errors = {
                                        formErrors: result.formErrors,
                                        fieldErrors: result.fieldErrors
                                    };
                                    return this.setState({errors, formSubmitted: false})
                                        .then(() => {
                                            throw {code: ERRORS.PAGE_SUBMIT_ERRORS};
                                        });
                                } else if(action.resultDatasource && (result.type === OPERATION_RESULT_TYPE.SUCCESS || result.type === OPERATION_RESULT_TYPE.DATA)) {
                                    return this.setDataFromSource(action.resultDatasource, result.data);
                                }
                            }
                        });

                } else if (action.type === ACTION_TYPE.OPERATION) {

                    return this.compileActionElement(e, element, true, data)
                        .then(result => {
                            if(result.proceed) {

                                const compiledAction = getActions(result.element, actionsPath)[index];

                                let $form = getClosestForEventWithAttribute(e, 'data-form');
                                $form = $form[0] ? $form : this.$container.find('[data-form]');
                                $form = $form[0] ? $form : this.$container;

                                const input = compiledAction.operation.input || {};

                                const files = {};

                                $form.find("input[type='file']").each((index, elem) => {
                                    if(elem.getAttribute("data-file-type") !== "LOCAL") {

                                        const key = elem.getAttribute("data-key");
                                        const elemPath = elem.getAttribute("data-elem-path");
                                        const layout = this.getLayout();
                                        const element = getValueForPathOrDefault(layout, elemPath);

                                        removePathForObject(input, key);

                                        if (elem.files.length > 0) {
                                            if (element.allowMultiple) {
                                                mapIndexed((f, index) => {
                                                    const k = `${key}.${index}`;
                                                    files[k] = f;
                                                }, elem.files || []);
                                            } else {
                                                files[key] = elem.files[0];
                                            }
                                        }
                                    }
                                });

                                return this.setState({clientErrors: null, errors: null})
                                    .then(() => runOperation(compiledAction.operation.id, input, files))
                                    .then(result => {
                                        if(result) {
                                            if(result.type === OPERATION_RESULT_TYPE.ERROR) {
                                                const errors = {
                                                    formErrors: result.formErrors,
                                                    fieldErrors: result.fieldErrors
                                                };
                                                return this.setState({errors})
                                                    .then(() => {
                                                        throw {code: ERRORS.PAGE_SUBMIT_ERRORS};
                                                    });
                                            } else if(compiledAction.resultDatasource && (result.type === OPERATION_RESULT_TYPE.SUCCESS || result.type === OPERATION_RESULT_TYPE.DATA)) {
                                                return this.setDataFromSource(compiledAction.resultDatasource, result.data);
                                            }
                                        }
                                    });
                            } else {
                                throw {code: ERRORS.PAGE_SUBMIT_ERRORS};
                            }
                        });

                } else if (action.type === ACTION_TYPE.GO_TO_PAGE) {

                    return this.compileActionElement(e, element, false, data)
                        .then(result => {
                            if(result.proceed) {

                                const compiledAction = getActions(result.element, actionsPath)[index];

                                return goToPage(compiledAction.page.id, compiledAction.page.props || {}, compiledAction.page.changeRoute, compiledAction.page.deepLink, compiledAction.page.replaceRoute);
                            } else {
                                throw {code: ERRORS.PAGE_SUBMIT_ERRORS};
                            }
                        });

                } else if (action.type === ACTION_TYPE.CLEAR_FORM) {

                    const $form = getClosestForEventWithAttribute(e, 'data-form');
                    $form.find('input').val('');

                } else if (action.type === ACTION_TYPE.GO_BACK) {

                    return goBack();

                } else if (action.type === ACTION_TYPE.RESET_FORM) {

                    return this.setState({}, true);

                } else if (action.type === ACTION_TYPE.FILE_DOWNLOAD) {

                    return this.compileActionElement(e, element, true, data)
                        .then(result => {
                            if(result.proceed) {

                                const compiledAction = getActions(result.element, actionsPath)[index];

                                return this.setState({clientErrors: null, errors: null})
                                    .then(() => runDownloadOperation(compiledAction.operation.id, compiledAction.operation.input))
                                    .then(result => {
                                        if(result && result.type === OPERATION_RESULT_TYPE.ERROR) {
                                            const errors = {
                                                formErrors: result.formErrors,
                                                fieldErrors: result.fieldErrors
                                            };
                                            return this.setState({errors})
                                                .then(() => {
                                                    throw {code: ERRORS.PAGE_SUBMIT_ERRORS};
                                                });
                                        }
                                    });
                            } else {
                                throw {code: ERRORS.PAGE_SUBMIT_ERRORS};
                            }
                        });

                    // const url = generateFileDownloadLinkForOperation(action.operation.id);
                    // return openUrl(url);

                } else if (action.type === ACTION_TYPE.OPEN_URL) {

                    return this.compileActionElement(e, element, false, data)
                        .then((result) => {
                            const compiledAction = getActions(result.element, actionsPath)[index];
                            return openUrl(compiledAction.url, compiledAction.openInNewWindow);
                        })
                    
                }
            });
        }, Promise.resolve(), actions);
    };

    buttonOnClick = (data, e) => {
        const $element = getClosestForEventWithAttribute(e, 'data-action');
        const elementPath = $element.attr('data-elem-path') || $element.attr('data-element-container-path');
        const elementId = $element.attr('data-element-container-id');

        return this.toggleButtonActionState(elementId, true)
            .then(() => {
                const layout = this.getLayout();
                const element = getValueForPathOrDefault(layout, elementPath);
                return this.runActionsForElement(e, element, "actions", data);
            })
            .finally(() => this.toggleButtonActionState(elementId, false))
            .catch(err => {
                if(!err || err.code !== ERRORS.PAGE_TRANSITION) throw err;
            });
    };

    onLocalFilesChanged = (e) => {
        const $el = $(e.target);
        const elementPath = $el.attr('data-elem-path');
        const key = $el.attr("data-key");
        const element = getValueForPathOrDefault(this.getLayout(), elementPath);
        if(element.chunkedUpload) this.state.chunkedUpload[key] = true;
        if(element.allowMultiple) {
            const files = this.state.localFiles[key] || [];
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files.item(i);
                file.ref = newUUID();
                file.allowUserRetryForServerFailures = element.allowUserRetryForServerFailures;
                file.allowUserRetryForNetworkFailures = element.allowUserRetryForNetworkFailures;
                files.push(file);
            }
            this.state.localFiles[key] = files;
        }
        else {
            this.state.localFiles[key] = e.target.files[0];
            this.state.localFiles[key].allowUserRetryForServerFailures = element.allowUserRetryForServerFailures;
            this.state.localFiles[key].allowUserRetryForNetworkFailures = element.allowUserRetryForNetworkFailures;
            this.state.localFiles[key].ref = newUUID();
        }

        $el.val("");
        return this.setState({}, true);
    };

    deleteLocalFile = (e) => {
        const $container = getClosestForEventWithAttribute(e, 'data-elem-path');
        const elementPath = $container.attr('data-elem-path');
        const element = getValueForPathOrDefault(this.getLayout(), elementPath);
        const key = element.key;
        const index = parseInt($container.attr('data-file-index'));
        if(element.allowMultiple) {
            this.state.localFiles[key] = R.remove(index, 1, this.state.localFiles[key]);
            if(R.isEmpty(this.state.localFiles[key])) {
                delete this.state.localFiles[key];
                delete this.state.chunkedUpload[key];
            }
        } else {
            delete this.state.localFiles[key];
            delete this.state.chunkedUpload[key];
        }
        return this.setState({}, true);
    };

    attachIndexForRepeaterElement = (element, index) => {
        element = R.omit(["repeater", "repeatView"], element);
        element = R.assoc('id', `${element.id}-${index}`, element);
        if(element.elements) {
            element.elements = R.map(el => this.attachIndexForRepeaterElement(el, index), element.elements);
        }
        return element;
    };

    compileElement = (element, compiledData) => element.parser ? compileLayoutWithParser(element, compiledData) : element;

    prepareRepeaterView = (element, elementPath = "", compiledData, clickHandler, changeHandler) => {
        if(element.repeater && !R.isEmpty(element.repeater)) {

            if(element.parser) {
                element = R.clone(element);
                const [repeaterParser, otherParser] = R.partition(p => R.startsWith("repeater.", p.key), element.parser);
                element = compileLayoutWithParser(R.assoc('parser', repeaterParser, element), compiledData);
                element.parser = otherParser;
            }

            const prepareView = (index, data) => {
                const newData = R.mergeLeft({
                    [element.repeater.itemRef]: data,
                    [element.repeater.indexRef]: index
                }, compiledData);
                // const itemPath = `${elementPath}.${index}`;
                const itemElement = this.attachIndexForRepeaterElement(element, index);
                const newClickHandler = this.buttonOnClick.bind(this, newData);
                const newChangeHandler = this.onFieldChange.bind(this, newData);
                return this.prepareView(R.clone(itemElement), elementPath, newData, newClickHandler, newChangeHandler);
            };

            let data = element.repeater.type === REPEATER_TYPE.TILL_COUNT ? R.range(0, parseInt(element.repeater.data) || 0) : element.repeater.data || [];
            data = Array.isArray(data) ? data : (typeof(data) === "object" ? R.toPairs(data) : data);
            const dataType = typeof(data);

            if(dataType === "boolean" || dataType === "number") return prepareView(0, data);
            return mapIndexed((data, index) => prepareView(index, data), data);
        }
    };

    renderSubPages = () => {

        if(!this.state.dataLoaded) return;

        let layout = this.props.page.layoutConfig;

        const subPageContainers = this.$container.find('[data-sub-page]');
        return R.reduce((acc, container) => {
            return acc.then(() => {

                let $form = this.$container.find('[data-form]');
                $form = $form.length > 0 ? $form : this.$container;

                const pageForm = serializeFormV2($form);

                const data = R.mergeLeft({pageForm}, this.getCompiledData());

                const containerId = container.getAttribute("id");
                const elementPath = container.getAttribute("data-elem-path");
                let element = getValueForPathOrDefault(layout, elementPath);
                if(!element || !element.value || !element.value.id) return Promise.resolve();

                element = R.clone(element);

                element = compileLayoutWithParser(element, data);
                element = compileLayoutWithParser(element, data, EVAL_AT.ACTION_SUBMIT);

                const elemProps = getValueForPathOrDefault(element, "value.props", {});
                const page = R.clone(this.props.getPageById(element.value.id));
                let props = {
                    pageProps: R.mergeLeft(elemProps, this.props.pageProps),
                    page,
                    subPage: true
                };
                props = R.mergeLeft(props, this.props);
                const alreadyRendered = this.subPages[containerId];
                const subPage = this.subPages[containerId] || new DynamicComponent(container, props, false, containerId);
                this.subPages[containerId] = subPage;
                return alreadyRendered ? subPage.updateProps(props) : subPage.render();
            });
        }, Promise.resolve(), subPageContainers);
    };

    onFieldChange = (data, e) => {
        const elementPath = getAttrValueForEventFromClosest(e, 'data-elem-path');

        const layout = this.getLayout();
        const element = getValueForPathOrDefault(layout, elementPath);
        return this.runActionsForElement(e, element, "onChange.actions", data);
    };
}