import {ROUTES} from '../config/app-config'
import DynamicComponent from "../components/dynamic-component/dynamic-component";

let getRoute = (containerId, component, parent, children) => {
    let r = {};
    if (containerId) r.containerId = containerId;
    if (component) r.component = component;
    if (parent) r.parent = parent;
    if (children) r.children = children;
    return r;
};

const config = {

    [ROUTES.DYNAMIC_PAGE]: getRoute('container', DynamicComponent)

};

export default config;