import Mustache from 'mustache';
import * as R from 'ramda';
// import Handlebars from 'handlebars';
import {getValueForPathOrDefault, setValueForPathOrDefault} from "ui-comps/utils/generic-utils";

export const PARSER_TYPE = {
    STRING: "STRING",
    JSON_PATH: "JSON_PATH",
    TEMPLATE: "TEMPLATE",
    EXPRESSION: "EXPRESSION"
};

export const EVAL_AT = {
    RENDER: "RENDER",
    ACTION_SUBMIT: "ACTION_SUBMIT"
};

export let compileTemplate = (template, data) => {
    // console.log("compiling-> " + template + " with data-> " + JSON.stringify(data));
    // return Handlebars.compile(template)(data);
    return Mustache.render(template, data);
};

// export const compileHandlebarTemplate = (template, data) => Handlebars.compile(template)(data);
//
// Handlebars.registerHelper('uexpr', function(operator, a, opts) {
//     switch (operator) {
//         case '-':
//             return -a;
//         case 'not':
//             return !a;
//         case 'isEmpty':
//             return a && R.isEmpty(a);
//         case 'isNotEmpty':
//             return !a || !R.isEmpty(a);
//         case 'isTrue':
//             return a === true || a === 'true';
//         case 'isFalse':
//             return a === false || a === 'false';
//         case 'exists':
//             return !R.isNil(a);
//         default:
//             throw "Unknown operator " + operator;
//     }
// });
//
// Handlebars.registerHelper('texpr', function(a, operator, b, c, opts) {
//     if (operator === 'between') {
//         return b <= a && a <= c;
//     } else {
//         throw "Unknown operator " + operator;
//     }
// });
//
// Handlebars.registerHelper('bexpr', function(a, operator, b, opts) {
//     switch (operator) {
//         case '+':
//             return a + b;
//         case '-':
//             return a - b;
//         case '*':
//             return a * b;
//         case '/':
//             return a / b;
//         case '%':
//             return a % b;
//         case '==':
//             return a == b;
//         case '===':
//             return a === b;
//         case '!=':
//             return a != b;
//         case '!==':
//             return a !== b;
//         case '<':
//             return a < b;
//         case '<=':
//             return a <= b;
//         case '>':
//             return a > b;
//         case '>=':
//             return a >= b;
//         case '&&':
//             return a && b;
//         case '||':
//             return a || b;
//         case 'contains':
//             return a ? R.includes(b, a) : false;
//         case 'hasKey':
//             return a ? R.includes(b, R.keys(a)) : false;
//         case 'hasValue':
//             return a ? R.includes(b, R.values(a)) : false;
//         case 'lowerCase':
//             return a && a.toLowerCase();
//         case 'upperCase':
//             return a && a.toUpperCase();
//         case 'startsWith':
//             return a && R.startsWith(a);
//         case 'endsWith':
//             return a && R.endsWith(a);
//
//
//         default:
//             throw "Unknown operator " + operator;
//     }
// });

export const compileInput = (input, data) => {
    if(!input || !data || R.isEmpty(input) || R.isEmpty(data)) return input;
    const inputType = typeof(input);
    if(inputType === "string") return compileTemplate(input, data);
    else if(inputType === "number" || inputType === "boolean") return input;
    else if(Array.isArray(input)) return R.map(v => compileInput(v, data), input);
    else return R.map(v => v && compileInput(v, data), input);
};

// export const compileExpressionInput = (input, data) => {
//     if(!input || !data || R.isEmpty(input) || R.isEmpty(data)) return input;
//     const inputType = typeof(input);
//     if(inputType === "string") return compileHandlebarTemplate(input, data);
//     else if(inputType === "number" || inputType === "boolean") return input;
//     else if(Array.isArray(input)) return R.map(v => compileExpressionInput(v, data), input);
//     else return R.map(v => v && compileExpressionInput(v, data), input);
// };

export const compileInputWithParser = (input, parser, data, evalAt = EVAL_AT.RENDER) => {

    if(!parser || R.isEmpty(parser) || !input || R.isEmpty(input)) return {input, parser};

    const updatedParser = R.filter(x => x, R.map(p => {
        // if(evalAt === (p.evalAt || EVAL_AT.RENDER)) {
        if(evalAt === EVAL_AT.ACTION_SUBMIT || (evalAt === EVAL_AT.RENDER && (!p.evalAt || (p.evalAt === EVAL_AT.RENDER)))) {
            if(p.type === PARSER_TYPE.JSON_PATH) {

                const valuePath = getValueForPathOrDefault(input, p.key);

                const value = getValueForPathOrDefault(data, valuePath);

                setValueForPathOrDefault(input, p.key, value);

                return null;

            } else if(p.type === PARSER_TYPE.TEMPLATE || p.type === PARSER_TYPE.EXPRESSION) {

                let value = getValueForPathOrDefault(input, p.key);

                value = compileInput(value, data);

                setValueForPathOrDefault(input, p.key, value);

                return null;
            }
        }
        return p;

    }, parser));

    return {input, parser: updatedParser};
};

export const compileLayoutWithParser = (layout, data, evalAt = EVAL_AT.RENDER) => {
    const {input: updatedLayout, parser} = compileInputWithParser(layout, layout.parser, data, evalAt);
    updatedLayout.parser = parser;
    return updatedLayout;
};
