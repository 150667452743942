import {
    getHashStringForUrl,
    getValueForPathSpecialCharsOrDefault
} from "ui-comps/utils/generic-utils";
import {KEY_CHAR, ROUTES} from "../config/app-config";
import {
    getCachedAppInfo, getDefaultPageRoute,
    getLoginPage,
    getPageById,
    getPageByRoute,
    getPageContext,
    getSignInPageRoute
} from "../config/app-cache";
import {getMyUserId, isUserSignedIn} from "../dao/user-profile-dao";
import EventHandler from "../event-handler";
import Events from "../config/events";
import * as LayoutManager from "../layout/layout-manager";
import {calculateSHA256ForArrayBuffer, getParamsMapForQueryString, goBack, gotoRoute, newUUID} from "./generic-utils";
import ERRORS from "../config/errors";

const R = require('ramda');

let tabindex = {};
let maxTabIndex = 0;

export const ELEMENT_TYPE = {
    IMAGE: "IMAGE",
    FORM: "FORM",
    BUTTON: "BUTTON",
    LABEL: "LABEL",
    CONTAINER: "CONTAINER",
    INPUT: "INPUT",
    PASSWORD: "PASSWORD",
    FILE_UPLOAD: "FILE_UPLOAD",
    IFRAME: "IFRAME",
    HEADER: "HEADER",
    ROW: "ROW",
    COLUMN: "COLUMN",
    BODY: "BODY",
    FOOTER: "FOOTER",
    HEADING: "HEADING",
    LINK: "LINK",
    PARAGRAPH: "PARAGRAPH",
    TEXT: "TEXT",
    FIELD: "FIELD",
    DIVIDER: "DIVIDER",
    FORM_ERRORS: "FORM_ERRORS",
    NEW_LINE: "NEW_LINE",
    PAGE: "PAGE",
    TABLE: "TABLE",
    UL: "UL",
    LI: "LI"
};

const ELEMENTS_WITH_TAB_INDEX = [ELEMENT_TYPE.BUTTON, ELEMENT_TYPE.INPUT, ELEMENT_TYPE.PASSWORD, ELEMENT_TYPE.LINK, ELEMENT_TYPE.FILE_UPLOAD, ELEMENT_TYPE.FIELD];

export let getValueForKeyFromApp = (app, key, defaultValue) => {
    let card = getValueForPathSpecialCharsOrDefault(app, "schema.card", {});
    let value = getValueForPathSpecialCharsOrDefault(card, key, defaultValue);
    if (hasKeyChar(value)) {
        let json = getValueForPathSpecialCharsOrDefault(app, "linearizedJSON", {});
        return getValueForPathSpecialCharsOrDefault(json, value.substring(1), defaultValue);
    }
    return value;
};

export let getValueForPath = (obj, path, def) => getValueForPathSpecialCharsOrDefault(obj, removeAllKeyCharsInPath(path), def);

export let hasKeyChar = (key) => key && R.startsWith(KEY_CHAR, key);
export let trimKeyChar = (key) => hasKeyChar(key) ? R.drop(1, key) : key;
export let removeAllKeyCharsInPath = (path) => R.replace(/\.:/, ".", hasKeyChar(path) ? R.drop(1, path) : path);
export let addKeyChar = (key) => hasKeyChar(key) ? key : `${KEY_CHAR}${key}`;
export let parentPathForKey = (key) => key && R.join(".", R.dropLast(1, key.split(".")));
export const childPathForKey = (key) => key && R.join(".", R.drop(1, key.split(".")));
export let siblingPathForKey = (key, sibling) => {
    // debugger;
    let pp = parentPathForKey(sibling);
    return pp ? `${pp}.${key}` : key;
};
export let preparePathForKeys = (...keys) => {
    keys = R.reject(x => x === null || x === undefined || x === "", keys);
    return R.join(".", keys);
};

export const setAppTitle = (page) => {
    const app = getCachedAppInfo();
    const title = page.title ? `${page.title} | ${app.title}` : app.title;
    document.title = title;
};

export const uploadFileByChunks = ({file, chunkSize = 5 * 1024 * 1024, uploader}) => {

    if(!uploader && typeof(uploader) !== "function") throw new Error("uploader must be a function");

    const reader = new FileReader();

    // let contentPercentage = 0;
    let contentUploaded = file.contentUploaded || 0;

    const uploadFile = (start = 0) => {

        return new Promise((resolve, reject) => {
            const nextSlice = start + chunkSize;
            const blob = file.slice(start, nextSlice);

            reader.onerror = function(event) {
                console.error("File reader error: ", event, reader.error);
                reader.abort();
                return reject(reader.error);
            };

            reader.onloadend = function (event) {
                if (event.target.readyState !== FileReader.DONE) {
                    return;
                } else if(event.target.error) return;

                // const index = event.target.result.indexOf(';base64,');
                // const data = event.target.result.substring(index + ';base64,'.length);
                const sha256 = calculateSHA256ForArrayBuffer(event.target.result);
                const data = new Blob([event.target.result], {type: 'application/octet-stream'});
                data.name = file.name;

                contentUploaded = contentUploaded + blob.size;
                // contentPercentage = contentPercentage + Number((blob.size/file.size * 100).toFixed(2));
                const contentPercentage = Number((contentUploaded/file.size * 100).toFixed(2));

                return uploader({
                    cid: newUUID(),
                    ref: file.ref,
                    size: file.size,
                    name: file.name,
                    type: file.type,
                    sha256,
                    contentLength: blob.size,
                    lastChunk: file.size <= nextSlice,
                    noOfChunks: Math.ceil(file.size/chunkSize),
                    currentChunk: Math.floor(start/chunkSize) + 1,
                    contentUploaded,
                    contentPercentage
                }, data)
                    .then(() => {
                        if (nextSlice < file.size) return uploadFile(nextSlice).then(resolve);
                        else return resolve();
                    })
                    .catch(reject);
            };
            reader.readAsArrayBuffer(blob);
        });
    };
    return uploadFile(file.contentUploaded || 0);
};

export const renderPageById = (pageId, pageProps) => {
    return renderPage(getPageById(pageId), pageProps);
};

export const renderPage = (page, pageProps) => {
    const loginPage = getLoginPage() || {};
    if(page.protected && page._id !== loginPage._id && !isUserSignedIn()) return EventHandler.push({name: Events.GO_TO_LOGIN});
    const _context = getPageContext();
    setAppTitle(page);
    resetTabIndex();
    return LayoutManager.render(ROUTES.DYNAMIC_PAGE, {page, pageProps, _context, getPageById});
};

export const getTabIndexForElement = (element, elementPath) => {
    return;
    // TODO tabIndex functionality
    // if(R.includes(element.type, ELEMENTS_WITH_TAB_INDEX)) {
    //     const index = tabindex[elementPath];
    //     if(R.isNil(index)) {
    //         maxTabIndex = maxTabIndex + 1;
    //         tabindex[elementPath] = maxTabIndex;
    //         return tabindex[elementPath];
    //     }
    //     return index;
    // }
};

export const resetTabIndex = () => {
    tabindex = {};
    maxTabIndex = 0;
};

export const renderPageByRoute = (route, pageProps) => {
    if(isUserSignedIn() && route === getSignInPageRoute()) {
        const rd = getRedirectUrl();
        if(rd) return window.location.href = rd;
        const defaultRoute = getDefaultPageRoute();
        if(defaultRoute !== route) return gotoRoute(defaultRoute);
    }
    const page = R.clone(getPageByRoute(route));
    return renderPage(page, pageProps);
};

export const getRedirectUrl = () => {
    const hash = getHashStringForUrl(window.location.href) || "";
    const qs = hash.split("?")[1];
    const params = qs && getParamsMapForQueryString(qs);
    return params && params.rd && decodeURIComponent(params.rd);
};