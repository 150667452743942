import Config from '../router/config'
import * as GenericUtils from '../utils/generic-utils'
import {getValueForPathOrDefault} from "ui-comps/utils/generic-utils";

const R = require('ramda');

let comps = {};
let compTypes = {};

let resolveComponents = (route) => {
    let comp = Config[route];
    if(!comp) alert('route not found');

    comp = R.clone(comp);
    comp.name = route;

    let comps = [];
    let parentComps = [];
    let childComps = [];

    if(comp.parent) {
        parentComps = resolveComponents(comp.parent);
    }

    comps = R.concat(comps, parentComps);
    comps = R.append(comp, comps);

    if(comp.children) {
        childComps = R.flatten(R.map(cc => resolveComponents(cc), comp.children));
    }

    comps = R.concat(comps, childComps);

    // Override the parent if for the same container id exists

    comps = R.map(x => R.last(x), R.groupWith((a, b) => a.containerId === b.containerId, comps));

    return comps;
};

export let render = (route, props) => {

    let newComps = resolveComponents(route);

    let compIds = R.map(c => c.containerId, newComps);
    let compMap = GenericUtils.mapFromList(newComps, 'containerId');

    let oldCompIds = R.values(comps).filter(c => !c.destroyed).map(c => c.containerId);

    // old component ids - new component ids
    let destroyableCompIds = R.difference(oldCompIds, compIds);

    let modifiedComponents = R.filter(containerId => {
        const oldPageId = getValueForPathOrDefault(comps[containerId], "props.page._id", null);
        const newPageId = getValueForPathOrDefault(props, "page._id", null);
        return (compTypes[containerId] != null && compTypes[containerId] !== compMap[containerId].component) || oldPageId !== newPageId;
    } , compIds);

    R.forEach(cid => {
        console.log("destroying component", cid);
        comps[cid] && comps[cid].destroy();

        // delete comps[cid];
        // delete compTypes[cid];

    }, R.concat(destroyableCompIds, modifiedComponents));

    let renderComp = (c, props) => {
        let Comp = c.component;
        if(!Comp) return Promise.resolve();
        let container = document.getElementById(c.containerId);
        if(Comp === compTypes[c.containerId]) {
            let oldComp = comps[c.containerId];
            let oldProps = oldComp && oldComp.props;
            let newProps = R.merge(oldProps || {}, props || {});

            oldComp && oldComp.reconstruct && oldComp.reconstruct(container, newProps);

            return oldComp && oldComp.render();
        } else {
            compTypes[c.containerId] = Comp;
            comps[c.containerId] = new Comp(container, props);
            window.currentComp = comps[c.containerId];
            return comps[c.containerId].render();
        }
    };

    return R.reduce((acc, c) => {
        return acc
            .then(_ => renderComp(c, props))
            .catch(err => {
                console.error('error while rendering componet ' + c.containerId + err);
                return renderComp(c, props);
            });
    }, Promise.resolve(), newComps);



    // let c = Config[route];
    // if(!c) alert('route not found');
    //
    // let pr = c.parent ? render(c.parent, props) : Promise.resolve();
    // return pr
    //     .then(_ => {
    //
    //         if(compTypes[c.containerId]) {
    //             if(compTypes[c.containerId] !== c.component) destory(route);
    //             else if(compTypes[c.containerId] === c.component) {
    //                 comps[c.containerId].props = R.merge(comps[c.containerId].props || {}, props || {});
    //                 return comps[c.containerId].render();
    //             }
    //         }
    //
    //         let Comp = c.component;
    //         compTypes[c.containerId] = Comp;
    //         comps[c.containerId] = new Comp(document.getElementById(c.containerId), props);
    //         return comps[c.containerId].render();
    //     });
};

// export let render = (route, props) => {
//     let c = Config[route];
//     if(!c) alert('route not found');
//
//     let pr = c.parent ? render(c.parent, props) : Promise.resolve();
//     return pr
//         .then(_ => {
//
//             if(compTypes[c.containerId]) {
//                 if(compTypes[c.containerId] !== c.component) destory(route);
//                 else if(compTypes[c.containerId] === c.component) {
//                     comps[c.containerId].props = R.merge(comps[c.containerId].props || {}, props || {});
//                     return comps[c.containerId].render();
//                 }
//             }
//
//             let Comp = c.component;
//             compTypes[c.containerId] = Comp;
//             comps[c.containerId] = new Comp(document.getElementById(c.containerId), props);
//             return comps[c.containerId].render();
//         });
// };

// let destory = (route) => {
//     // return;
//     let childs = getChilds(route);
//     for(var i=0; i<childs.length; i++){
//         let containerId = childs[i].containerId;
//         delete compTypes[containerId];
//         let comp = comps[containerId];
//         comp && comp.destroy();
//         delete comps[containerId];
//     }
//     delete compTypes[route];
//     let comp = comps[route];
//     comp && comp.destroy();
//     delete comps[route];
// };
//
// let getChilds = (route) => {
//     let transform = (r) => ({route: r, containerId: Config[r].containerId});
//     let childRoutes = R.filter(r => Config[r].parent === route, R.keys(Config)).map(transform);
//     let childs = R.flatten(R.map(rc => getChilds(rc.route), childRoutes));
//     childs = R.concat(childs, childRoutes);
//
//     // for(var key in Config){
//     //     if(Config[key].parent === route){
//     //         let grandChilds = getChilds(key);
//     //         for(var i = 0; i < grandChilds.length; i++){
//     //             childs.push(grandChilds[i]);
//     //         }
//     //         childs.push({route: key, containerId: Config[key].containerId});
//     //     }
//     // }
//     return childs;
// };