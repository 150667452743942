import EventsHandler from '../event-handler'
import Events from '../config/events'
import * as UserProfileService from '../service/user-profile-service'

import AppCache from '../config/app-cache'
import {SESSION_ID} from '../config/app-config'
import {getValueForPathOrDefault} from 'ui-comps/utils/generic-utils'

const R = require('ramda');

export let isUserSignedIn = () => !R.isEmpty(AppCache.session || {});

export let getSessionId = () => AppCache.session && AppCache.session[SESSION_ID];

export let setSession = (session) => {
    AppCache.session = session;
    return Promise.resolve();
};

export let getSession = () => AppCache.session;

export const removeUserSession = () => delete AppCache['session'];

export let logout = () => {
    removeUserSession();
    return EventsHandler.push({name: Events.GO_TO_LOGIN});
};

export let createSession = () => {
    if(isUserSignedIn()) return Promise.resolve();
    return UserProfileService.autoLogin();
};

export let getProfile = () => AppCache.session;
