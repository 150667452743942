import Backbone from 'backbone'

import {ROUTES} from '../config/app-config'
import {getParamsMapForQueryString} from 'ui-comps/utils/generic-utils'
import _ from 'underscore'
import {getTitle} from '../utils/domain-utils'
import {appendParamsAsHashStringToUrl, decodeURIData} from "../utils/generic-utils";
import ModalContainer from "../components/modal-container/modal-container-v2";
import {getDefaultPage, getDefaultPageRoute} from "../config/app-cache";
import $ from 'jquery';
import {renderPageByRoute} from "../utils/app-utils";

const R = require('ramda');

const dynamicRoute = "dynamicRoute";

let Router = Backbone.Router.extend({

    noAuthURIs: [ROUTES.SIGN_IN, ROUTES.FORGOT_PASSWORD, "signup", ROUTES.VERIFY_EMAIL, ROUTES.SUCCESS_VIEW, ROUTES.RESET_PASSWORD,
    ROUTES.REGISTER_USER, ROUTES.SSO_LOGIN],

    currentRouteParams: {},

    titles: {

        "": () => getTitle(),

        [ROUTES.HOME]: 'Home',

        [ROUTES.SIGN_IN]: "Signin",
        [ROUTES.FORGOT_PASSWORD]: "Forgot Password",
        [ROUTES.RESET_PASSWORD]: () => "Reset Password",
        [ROUTES.REGISTER_USER]: () => "Registration",
        [ROUTES.SSO_LOGIN]: () => "SSO",

        [ROUTES.APP_DETAIL]: () => "App Detail",
        [ROUTES.MARKETPLACE]: "Marketplace",
        [ROUTES.WORKSPACE]: "Workspace",

        [ROUTES.INTEGRATIONS]: "Integrations",
        [ROUTES.INTEGRATIONS_EDIT]: "Edit Integration",

        [ROUTES.NEW_SERVICE]: "New Service",
        [ROUTES.EDIT_SERVICE]: "Edit Service",

        [ROUTES.FLOWS]: "Flows",
        [ROUTES.NEW_FLOW]: "New Flow",
        [ROUTES.EDIT_FLOW]: "Edit",
        [ROUTES.VIEW_FLOW]: (data) => decodeURIData(data).name,

        [ROUTES.CREDENTIALS]: "Credentials",

        [ROUTES.ENTITIES]: "Entities",

        [ROUTES.OPERATIONS]: "Operations",

        [ROUTES.CATALOG]: "Catalog",

        [ROUTES.ANALYTICS]: "Analytics",

        [ROUTES.AUTHENTICATIONS]: "Authentications",

        [ROUTES.USERS]: "Users"
    },

    routes: {
        '*path': 'dynamicRoute'
    },

    /*
     *Override navigate function
     *@param {String} route The route hash
     *@param {PlainObject} options The Options for navigate functions.
     *              You can send a extra property "params" to pass your parameter as following:
     *              {
     *               params: 'data'
     *              }
     **/
    navigate: function(route, options) {
        const routeOption = {
                trigger: true
            },
            params = (options && options.params) ? options.params : null;
        $.extend(routeOption, options);
        delete routeOption.params;

        //set the params for the route
        this.param(route, params);
        Backbone.Router.prototype.navigate(route, routeOption);
    },

    /*
     *Get or set parameters for a route fragment
     *@param {String} fragment Exact route hash. for example:
     *                   If you have route for 'profile/:id', then to get set param
     *                   you need to send the fragment 'profile/1' or 'profile/2'
     *@param {Any Type} params The parameter you to set for the route
     *@return param value for that parameter.
     **/
    param: function(fragment, params) {
        let matchedRoute;
        _.any(Backbone.history.handlers, function(handler) {
            if (handler.route.test(fragment)) {
                matchedRoute = handler.route;
            }
        });
        if (params !== undefined) {
            this.currentRouteParams = params;
        }

        return this.currentRouteParams;
    },

    [dynamicRoute]: function (routeAttributes) {
        if(!routeAttributes.fragment) return this.goToRoute(getDefaultPageRoute());
        return renderPageByRoute(routeAttributes.fragment, routeAttributes.params);
    },

    goToRoute: function (route, params, deepLink, replaceRoute) {
        console.log("routing: ", route, "deepLink: ", deepLink);
        const replace = !!replaceRoute;
        if(deepLink) {
            route = appendParamsAsHashStringToUrl(`#${route}`, params);
            console.log("new route: ", route);
            return this.navigate(route, {trigger: true, replace});
        }
        return this.navigate(route, {trigger: true, replace, params});
    },

    reloadCurrentRoute: function () {
        return Backbone.history.loadUrl(Backbone.history.fragment);
    },

    reloadRoute: function (route) {
        return Backbone.history.loadUrl(route);
    },

    default: function () {
        const page = getDefaultPage();
        return this.goToRoute(page.routePath);
        // return this.goToRoute(ROUTES.DEFAULT);
    },

    goToHome: function () {
        return this.goToRoute(ROUTES.DEFAULT);
    },

    execute: function (callback, args, name) {

        // $('body').scrollTop(0);

        let attribs = this.getCurrentRouteAttributes();
        let cr = attribs.route;

        let isNoAuthRoute = R.includes(cr, this.noAuthURIs);

        ModalContainer.removeAllModals();

        // if (UserProfileDAO.isUserSignedIn()) {
        //     if (cr === ROUTES.SIGN_IN) return this.goToHome();
        // } else if (!isNoAuthRoute) return EventsHandler.push({name: Events.GO_TO_SIGNIN});

        // if(UserProfileDAO.isUserSignedIn() && window.env.adminApp !== UserProfileDAO.amIAdmin()) return UserProfileDAO.logout();

        // let notAllowed = cr === "*actions" ? false : UserProfileDAO.isUserSignedIn() && !isRouteAllowedForMe(cr);

        // let notAllowed = cr === "*actions" ? false : UserProfileDAO.isUserSignedIn();
        //
        // if(notAllowed) return this.goBack();

        document.title = this.getTitleForRoute(cr, args);

        args = R.prepend(attribs, args);

        if (callback) callback.apply(this, args);
    },

    start: function () {
        window.history.scrollRestoration = 'manual';
        return Backbone.history.start();
    },

    goBack: function () {
        return Backbone.history.history.back();
    },

    getCurrentRoute: function () {
        return this.getCurrentRouteAttributes().route;
    },

    getCurrentFragement: function () {
        return Backbone.history.fragment;
    },

    getRouteAttributesForURI: function (uri) {
        let Router = this,
            fragment = uri,
            routes = _.pairs(Router.routes),
            route = null,
            params = null,
            handler = null,
            matched = false;

        matched = _.find(routes, function (handler) {
            route = _.isRegExp(handler[0]) ? handler[0] : Router._routeToRegExp(handler[0]);
            return route.test(fragment);
        });

        if (matched) {
            // NEW: Extracts the params using the internal
            // function _extractParameters
            params = R.filter(x => x, Router._extractParameters(route, fragment) || []);
            route = matched[0];
            handler = matched[1];
        }

        params = R.mergeLeft(R.mergeAll(R.map(getParamsMapForQueryString, params)), this.currentRouteParams || {});

        fragment = fragment.split("?")[0];

        return {
            route,
            fragment,
            handler,
            params
        };
    },

    getCurrentRouteAttributes: function () {
        return this.getRouteAttributesForURI(Backbone.history.fragment);
    },

    getBreadCrumbForCurrentURI: function () {
        let ra = this.getCurrentRouteAttributes();
        return this.getBreadCrumbForRoute(ra.route, ra.params);
    },

    getTitleForCurrentURI: function () {
        let ra = this.getCurrentRouteAttributes();
        return this.getTitleForRoute(ra.route, ra.params);
    },

    getTitleForRoute: function (route, params = []) {
        let bc = this.getBreadCrumbForRoute(route, params);
        let rts = R.filter(x => x, R.reverse(bc));
        return R.join(" | ", rts);
    },

    getBreadCrumbForRoute: function (route, params = []) {

        // if(!this.titles[route]) return [];

        params = R.filter(x => x, params);

        // if(breadCrumb[route]) return breadCrumb[route];

        route = route && route.trim();

        if (!route || R.isEmpty(route) || route === "/") {
            let title = this.titles[route];
            if(typeof title === "function") return [title(...params)];
            else return [title];
        }

        let parts = route.split("/");

        let parentURI = R.join("/", R.dropLast(1, parts));

        let cr = R.last(parts);

        let isDynamicRoute = R.startsWith(":", cr);

        let bc = this.getBreadCrumbForRoute(parentURI, isDynamicRoute ? R.dropLast(1, params) : params);

        // let value = isDynamicRoute && this.titles[route] ? this.titles[route](...params) : this.titles[route];
        let value = this.titles[route] ? (typeof(this.titles[route]) === "function" ? this.titles[route](...params) : this.titles[route]) : "";

        // if(!isDynamicRoute) breadCrumb[route] = result;

        return R.filter(x => x, R.append(value, bc));
    }
});

let router = new Router();

export default router;
