import './config/env-admin'
import $ from 'jquery'
import Bacon from 'baconjs'

import 'jquery.cookie'
import * as UserProfileDAO from './dao/user-profile-dao'
import Router from './router/router'
import * as GenericUtils from './utils/generic-utils'
import {getCachedAppId, getCachedAppInfo, setInCache} from "./config/app-cache";
import {getAppConfig, getAppLayout, getAppStyleClasses, loadAllActionScripts} from "./service/app-service";
import { getStyleForElement } from './utils/ui-utils'

window.$ = window.jQuery = $;
const R = window.R = require('ramda');
window.m = require('mithril');

$.fn.asEventStream = Bacon.$.asEventStream;

let params = GenericUtils.getParamsMapForQueryString(window.location.search.substring(1)) || {};
window.initParams = params || {};

let initApp = () => {
    window.env = window.env || {};
    return loadApp()
        .then(getCachedAppInfo)
        .then(config => {
            if(config.hasSession) return UserProfileDAO.createSession();
        });
};

const loadApp = () => {
    const appId = window.appId;
    if(!appId) throw new Error("app is not initialized properly");
    setInCache("appId", appId);
    return getAppConfig(appId)
        .then(appInfo => setInCache("appInfo", appInfo))
        .then(loadCommonStyles)
        .then(loadStyleClasses)
        .then(() => getAppLayout(appId))
        .then(appLayout => setInCache("appLayout", appLayout))
        .then(() => loadAllActionScripts(appId))
        .then(scriptActions => setInCache("scriptActions", scriptActions));
};

const loadCommonStyles = () => {
    const app = getCachedAppInfo();
    const styles = app.commonStyles || "";
    if(styles) {
        $('#app-styles').html(styles);
    }
};

const loadStyleClasses = () => {
    const appId = getCachedAppId();
    return getAppStyleClasses(appId)
        .then(classes => {
            const classString = R.join("", R.map(claz => {
                return `.sc-${claz._id}{${getStyleForElement(claz)}}`
            }, classes || []));
            $('#app-style-classes').html(classString);
        });
};

// window.addEventListener("resize", () => Router.reloadCurrentRoute());

initApp()
    .finally(_ => {
        return Router.start();
    });

// MainRenderer.startApp(false)
//     .then(_ => initApp())
//     .then(_ => Router.start());

