import Bacon from 'baconjs'

import Router from './router/router'
import Events from './config/events'
import {getSignInPageRoute} from "./config/app-cache";

let createEventStream = () => new Bacon.Bus();

const eventsHandler = createEventStream();

let gotoSignIn = () => {
    const route = getSignInPageRoute();
    return Router.goToRoute(route + `?rd=${encodeURIComponent(window.location.href)}`);
};

let eventsMap = {
    [Events.GO_TO_HOME]: () => Router.goToHome(),
    [Events.GO_TO_LOGIN]: gotoSignIn,
    [Events.GO_TO_ROUTE]: (event) => event.route && Router.goToRoute(event.route)
};

eventsHandler.onValue(event => {
    let handler = eventsMap[event.name];
    handler && handler(event);
});

export default eventsHandler;
