import * as HttpUtils from '../utils/http-utils'
import Endpoints from '../config/endpoints'
import * as UserProfileDAO from '../dao/user-profile-dao'
import {SESSION_ID} from '../config/app-config'
import {getCachedAppId} from "../config/app-cache";

export let autoLogin = () => {
    return getUserPrincipal()
        .then(principal => {
            if (principal && principal.exists) {
                return setUserProfileInSession(principal.principal);
            }
        });
};

let setUserProfileInSession = (profile) => {
    profile[SESSION_ID] = profile.id;
    return UserProfileDAO.setSession(profile);
};

export let updateProfile = (profile) => {
    return HttpUtils.postData(Endpoints.GET_PROFILE, profile)
        .then(setUserProfileInSession)
};

export let updatePassword = (data) => HttpUtils.postData(Endpoints.UPDATE_PASSWORD, data);

export let getUserPrincipal = () => HttpUtils.getData(Endpoints.GET_USER_PRINCIPAL(getCachedAppId()));

export let signIn = (data) => {
    // data.adminApp = window.env.adminApp;
    data.env = window.env;
    return HttpUtils.postData(Endpoints.SIGN_IN, data)
        .then(autoLogin)
        .then(_ => true)
        .catch(resp => {
            if(resp.status === 400) return false;
            throw resp;
        });
};

export let emailCheck = (data) => {
    data.adminApp = window.env.adminApp;
    return HttpUtils.postData(Endpoints.FORGOT_PASSWORD, data)
        .then(_ => ({success: true}))
        .catch(resp => {
            if(resp.status === 400) return ({success: false});
            throw resp;
        });
};

export let setNewPassword = (data, token) => HttpUtils.postData(Endpoints.RESET_PASSWORD, data, {token});
