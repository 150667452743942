
export let getLogoUrl = () => window.domainConfig && window.domainConfig.logoUrl;

export let getClientAppLogoUrl = () => window.domainConfig && window.domainConfig.clientAppLogoUrl;

export let getTitle = () => window.domainConfig && window.domainConfig.title;

export let getClientAppName = () => window.domainConfig && window.domainConfig.clientAppName;

export let getClientAppInstallerName = () => window.domainConfig && window.domainConfig.clientAppInstallerName;

export let getToggleActiveIconUrl = () => window.domainConfig && window.domainConfig.toggleActiveIconUrl;

export let getToggleInActiveIconUrl = () => window.domainConfig && window.domainConfig.toggleInActiveIconUrl;

export let getAdminAppName = () => window.domainConfig && window.domainConfig.title;
