/**
 * Created by Sabbu on 01/09/16.
 */

import $ from 'jquery'
import * as UserProfileDAO from '../dao/user-profile-dao'

const R = require('ramda');

export const HTTP_METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    OPTIONS: 'OPTIONS',
    PATCH: 'PATCH'
};

let makeCall = (url, method, data, headers, cache= false, responseAsJSON = true) => {
    let pr = new Promise(function(resolve, reject) {
            let payload = {
                contentType: 'application/json', // payload type being sent
                // dataType: 'json', // payload being expected as response
                method,
                headers: headers || {},
                cache,
                dataType: 'text'
            };
            if(responseAsJSON) payload.dataType = "json";
            if(data && !R.isEmpty(data)) payload.data = JSON.stringify(data);
            $.ajax(url, payload).done(resolve).fail(reject);
        })
        .then(x => x)
        .catch(resp => checkForSessionTimeout(resp));
    return pr;
};

export let postData = (url, data, headers, responseAsJSON = true) => makeCall(url, HTTP_METHOD.POST, data, headers, false, responseAsJSON);

export let patchData = (url, data, headers, responseAsJSON = true) => makeCall(url, HTTP_METHOD.PATCH, data, headers, false, responseAsJSON);

export let postDataWithNoResponse = (url, data, headers) => makeCall(url, HTTP_METHOD.POST, data, headers, false, false);

export let putData = (url, data, headers) => makeCall(url, HTTP_METHOD.PUT, data, headers);

export let getData = (url, data, headers, cache= true) => makeCall(url, HTTP_METHOD.GET, data, headers, cache);

export let getResponseAsString = (url, data, headers, cache= true) => makeCall(url, HTTP_METHOD.GET, data, headers, cache, false);

export let deleteData = (url, data, headers, cache= true, responseAsJSON = true) => makeCall(url, HTTP_METHOD.DELETE, data, headers, cache, responseAsJSON);

export let multipart = (url, data, files, headers, method = HTTP_METHOD.POST) => {
    let parts = new FormData();
    R.forEach(key => {
        if(!R.isNil(data[key])) {
            parts.append(key, typeof(data[key]) === "object" ? JSON.stringify(data[key]) : data[key]);
        }
    }, R.keys(data || {}));

    R.forEach(fid => {
        files[fid] && parts.append(fid, files[fid], files[fid].name);
    }, R.keys(files || {}));

    headers = headers || {};

    return new Promise((resolve, reject) => {
        $.ajax({
            enctype: 'multipart/form-data',
            headers,
            url,
            data: parts,
            cache: false,
            contentType: false,
            processData: false,
            method,
            type: method, // For jQuery < 1.9
        })
            .done(resolve)
            .fail(reject);
    }).then(x => x).catch(resp => checkForSessionTimeout(resp));
};

export let sendFileInBinaryForm = (url, file, headers, responseAsJSON = true, method = HTTP_METHOD.POST) => {

    return convertFileToArrayBuffer(file)
        .then(data => {
            return new Promise(function(resolve, reject) {
                let payload = {
                    contentType: 'application/octet-stream', // payload type being sent
                    // dataType: 'json', // payload being expected as response
                    type: method,
                    headers: headers || {},
                    processData: false,
                    cache: false,
                    data,
                    dataType: 'text'
                };
                if(responseAsJSON) payload.dataType = "json";
                $.ajax(url, payload).done(resolve).fail(reject);
            })
                .then(x => x)
                .catch(resp => checkForSessionTimeout(resp));
        })

};

export let convertFileToArrayBuffer = (file) => {

    return new Promise(function(resolve) {
        const reader = new FileReader();

        reader.onload = function(e) {
            const data = new Uint8Array(e.target.result);
            resolve(data);
        };

        reader.readAsArrayBuffer(file);
    });
};


export let postFormUrlEncoded = (url, data, headers, responseAsJSON = false) => {
    return new Promise((resolve, reject) => {
        $.ajax({
            headers,
            url,
            data,
            method: "POST",
            type: "POST",
            dataType: responseAsJSON ? "json" : "text"
        })
            .done(resolve)
            .fail(reject);
    }).then(x => x).catch(resp => checkForSessionTimeout(resp));
};

export const getJSONP = (url, callback) => {
    return $.ajax({
        url,
        dataType: "jsonp",
        jsonpCallback: callback
    });
};

export let validateIfProtocolExistForUrl = (url) =>{
    let regToCheckProtocolExist = /^(?:(ht|f)tp(s?):\/\/)?/;
    return url.match(regToCheckProtocolExist);
};

export let removeSecuredAccessForImage = (url) =>{
    if(url){
        url =  url.replace(/^https:\/\//i, 'http://');
    }
    return url;
};

let checkForSessionTimeout = (response) => {
    // if(response.status === 401 && response.responseJSON.name === "INVALID_SESSION") {
    if(response.status === 401) {
        UserProfileDAO.logout();
        throw response;
    }
    throw response;
};

export const getDownloadFileNameFromHeaders = (headers) => {
    const cd = headers && headers.get('content-disposition');
    const kvs = R.mergeAll(R.map(k => {
        const ps = k.split("=");
        return {[ps[0]]: ps[1]};
    }, R.map(k => k.trim(), cd.split(";"))));
    const fileName = kvs["filename*"] || kvs["filename"] || kvs["name"];
    return R.startsWith('"', fileName) ? fileName.substr(1, fileName.length() - 1) : fileName;
};
