import React from "react";
import {getValueForPathOrDefault, isValidUrl} from "ui-comps/utils/generic-utils";
import {getRouteByPageId} from "../config/app-cache";
import {gotoRoute} from "./generic-utils";
import Endpoints from '../config/endpoints';
import Router from '../router/router';
import {renderPageById} from "./app-utils";

const R = require('ramda');

window.m = require('mithril');

export const UI_TYPE = {
    DROP_DOWN: "DD",
    INPUT: "INPUT",
    TEXTAREA: "TEXTAREA",
    CHECKBOX: "CHECKBOX",
    RADIO: "RADIO",
    CONDITION: "CONDITION",
    OBJECT: "OBJECT"
};

export const UI_TYPE_DD_LIST = [
    {
      label: "Select UI type",
      value: ""
    },
    {
        label: "Dropdown",
        value: "DD"
    },
    {
        label: "Input",
        value: "INPUT"
    },
    {
        label: "Text Area",
        value: "TEXTAREA"
    },
    {
        label: "Checkbox",
        value: "CHECKBOX"
    },
    {
        label: "Radio",
        value: "RADIO"
    },
    {
        label: "Condition",
        value: "CONDITION"
    },
    {
        label: "Object",
        value: "OBJECT"
    }
]

export const PARSER = [
    {
      label: "Select a parser",
      value: ""
    },
    {
        label: "Json Path",
        value:"JSON_PATH"
    },
    {
        label: "Template",
        value: "TEMPLATE"
    },
    {
        label: "User Input",
        value: "USER_INPUT"
    }
];

export const PICK_LIST_TYPE = {
    FIXED: "Fixed",
    SERVICE: "Service"
};

export const loadingView = () => (
    <div key={Date.now()} className="loading-wrapper">
        <div className="loading-circle"/>
        <span className="loading-text">Loading...</span>
    </div>
);

export const loadingCircleView = () => (
    <div key={Date.now()} className="loading-wrapper loading-inline">
        <div className="loading-circle"/>
    </div>
);

export const errorView = (errorDesc) => (
    <div className='error-view'>{errorDesc}</div>
);

export const openUrl = (url, newWindow = false) => {
    const el = document.createElement("a");
    el.setAttribute("href", url);
    debugger;
    if(newWindow) el.setAttribute("target", "_blank");
    el.click();
};

export const havingValidInput = (inputSchema, input) => {
    const requiredFields = R.map(input => input.key, R.filter(input => input.required, inputSchema || []));
    return R.all(v => v, R.map(path => getValueForPathOrDefault(input, path), requiredFields));
    // const inputPaths = getAllPathsForObjectWithPath(input, ".*");
    // console.log("validating input:");
    // console.log(requiredFields);
    // console.log(input);
    // console.log(inputPaths);
    // return R.isEmpty(R.difference(requiredFields, inputPaths));
};

export const getSampleInputForSchema = (inputSchemaArray) => {

    return R.reduce((acc, inputSchema) => {

        let value = `${inputSchema.key} value`;

        if(inputSchema.uiType === UI_TYPE.CONDITION) {
            value = {
                operands: [`${inputSchema.key} dummy operand 1`, `${inputSchema.key} dummy operand 2`],
                operator: `${inputSchema.key} dummy operator`
            };
        } else if(inputSchema.uiType === UI_TYPE.OBJECT) {
            value = getSampleInputForSchema(inputSchema.keyValues);
        } else if(inputSchema.multiSelect) value = ["v1", "v2"];

        if(R.equals(String(inputSchema.multiValue) ,"true")) value = [value];

        return R.assoc(inputSchema.key, value, acc);

    }, {}, inputSchemaArray);
};

export const goToPage = (pageId, pageProps = {}, changeRoute, deepLink, replaceRoute) => {
    const route = getRouteByPageId(pageId);
    if(!changeRoute || route === Router.getCurrentFragement()) return renderPageById(pageId, pageProps);
    return gotoRoute(route, pageProps, deepLink, replaceRoute);
};

// export const prepareInlineStyles = (attributes) => {
//     if(!attributes || R.isEmpty(attributes)) return;
//     return R.reduce((acc, key) => {
//         return acc ? `${acc}; ${key}: ${attributes[key]}` : `${key}: ${attributes[key]}`;
//     }, "", R.keys(attributes));
// };
export const prepareInlineStyles = (attributes) => {
    if(!attributes || R.isEmpty(attributes)) return;
    return R.reduce((acc, key) => {
        if(!attributes[key]) return acc;
        const k = unCapitalizeKey(key);
        return acc ? `${acc};${k}:${attributes[key]}` : `${k}:${attributes[key]}`;
    }, "", R.keys(attributes));
};

export const getStyleClassStringForElement = (element, defaultClassString = '') => {
    const classes = element.styleClasses;
    if(classes && !R.isEmpty(classes)) {
        const classString = R.join(" ", R.map(c => `sc-${c}`, classes));
        return defaultClassString ? `${defaultClassString} ${classString}` : classString;
    }
    return defaultClassString;
};

export const hasClickActions = (el) => el.actions && !R.isEmpty(el.actions);

export const getStyleForElement = (element) => {
    const cursorStyle = hasClickActions(element) ? "cursor: pointer;" : "";
    const style = element.style;
    const guiAttributesStyle = prepareInlineStyles(element.guiAttributes) || "";
    if(style) {
        return `${cursorStyle}${style};${guiAttributesStyle};${prepareBackgroundImage(element)}`;
    }
    return `${cursorStyle}${guiAttributesStyle};${prepareBackgroundImage(element)}`;
};

export const prepareBackgroundImage = (element) => {
    const url = element.backgroundImage;
    const imageUrl = url ? (isValidUrl(url) ? url : Endpoints.APP_ASSET_DOWNLOAD_URL(url)) : "";
    return imageUrl ? `background-image: url("${imageUrl}");` : "";
};

export const unCapitalizeKey = (key) => {
    let total = "";
    for(const i in key) {
        const ch = key.charAt(i);
        total += isUpperCaseChar(ch) ? '-' + ch.toLowerCase() : ch;
    }
    return total;
};

export const isUpperCaseChar = (char) => char === char.toUpperCase();
