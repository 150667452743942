
const R = require('ramda');

export const SESSION_ID = "sid";

// Roles ordered based on level
export const USER_ROLES = {
    SUPER_ADMIN: {
        id: "SUPER_ADMIN",
        name: "Super Admin",
        level: 6
    },
    DEVELOPER: {
        id: "DEVELOPER",
        name: "Developer",
        level: 5
    },
    ADMIN: {
        id: "ADMIN",
        name: "Admin",
        level: 4
    },
    CONTRIBUTOR: {
        id: "CONTRIBUTOR",
        name: "Contributor",
        level: 3
    },
    CREATOR: {
        id: "CREATOR",
        name: "Creator",
        level: 2
    },
    VIEWER: {
        id: "VIEWER",
        name: "Viewer",
        level: 1
    },
};

export const ROUTES = {

    DEFAULT: 'home',

    DYNAMIC_PAGE: "DYNAMIC_PAGE",

    /******************************* Common Routes *****************************/

    HOME: 'home',
    SIGN_IN: 'signin',
    FORGOT_PASSWORD: 'password/forgot',
    RESET_PASSWORD: 'password/reset/:token',
    SSO_LOGIN: 'sso/login/:token',
    SUCCESS_VIEW: 'passwordReset/successful',
    VERIFY_EMAIL: 'email/verify',
    REGISTER_USER: 'register/:token',

    WORKSPACE: "workspace",
    MARKETPLACE: "marketplace",
    APP_DETAIL: "apps/:appId",

    INTEGRATIONS: "integrations",
    INTEGRATIONS_EDIT: "integrations/:integrationId/edit",
    INTEGRATION_DETAIL: "integrations/:integrationId",
    NEW_SERVICE: "integrations/:integrationId/services/new",
    EDIT_SERVICE: "integrations/:integrationId/services/:serviceId/edit",
    FLOWS: "flows",
    NEW_FLOW: "flows/:flowId/new",
    VIEW_FLOW: "flows/:flowId",
    EDIT_FLOW: "flows/:flowId/edit",

    CREDENTIALS : "credentials",

    ENTITIES: "entities",
    OPERATIONS: "entities/:entityId/operations",

    CATALOG: "catalog",

    ANALYTICS: "analytics",

    AUTHENTICATIONS: "authentications",

    TENANTS: "tenants",

    USERS: "users"
};

export const ALL_USER_ROLE_IDS = R.map(r => r.id, R.sortWith([R.descend(R.prop('level'))], R.values(USER_ROLES)));

const TENANT_ADMIN_USER_ROLE_IDS = [USER_ROLES.ADMIN.id];

const USER_ROLE_IDS_LEVEL = {
    SIX: R.take(1, ALL_USER_ROLE_IDS),
    FIVE: R.take(2, ALL_USER_ROLE_IDS),
    FOUR: R.take(3, ALL_USER_ROLE_IDS),
    THREE: R.take(4, ALL_USER_ROLE_IDS),
    TWO: R.take(5, ALL_USER_ROLE_IDS),
    ONE: ALL_USER_ROLE_IDS
};

export const ROLES_BASED_ROUTES = {

    /******************************* Common Routes *****************************/
    [ROUTES.HOME]: ALL_USER_ROLE_IDS,
    [ROUTES.SIGN_IN]: ALL_USER_ROLE_IDS,
    [ROUTES.RESET_PASSWORD]: ALL_USER_ROLE_IDS,
    [ROUTES.FORGOT_PASSWORD]: ALL_USER_ROLE_IDS,
    [ROUTES.SUCCESS_VIEW]: ALL_USER_ROLE_IDS,
    [ROUTES.VERIFY_EMAIL]: ALL_USER_ROLE_IDS,
    [ROUTES.REGISTER_USER]: ALL_USER_ROLE_IDS,

    [ROUTES.MARKETPLACE]: ALL_USER_ROLE_IDS,

    /******************************* Level 5 Roles Routes *****************************/

    [ROUTES.ENTITIES]: USER_ROLE_IDS_LEVEL.FIVE,
    [ROUTES.OPERATIONS]: USER_ROLE_IDS_LEVEL.FIVE,
    [ROUTES.INTEGRATIONS]: USER_ROLE_IDS_LEVEL.FIVE,
    [ROUTES.INTEGRATION_DETAIL]: USER_ROLE_IDS_LEVEL.FIVE,
    [ROUTES.INTEGRATIONS_EDIT]: USER_ROLE_IDS_LEVEL.FIVE,
    [ROUTES.EDIT_SERVICE]: USER_ROLE_IDS_LEVEL.FIVE,
    [ROUTES.NEW_SERVICE]: USER_ROLE_IDS_LEVEL.FIVE,

    /******************************* Level 4 Roles Routes *****************************/

    [ROUTES.TENANTS]: R.difference(USER_ROLE_IDS_LEVEL.FOUR, [USER_ROLES.DEVELOPER.id]),

    [ROUTES.USERS]: R.difference(USER_ROLE_IDS_LEVEL.FOUR, [USER_ROLES.DEVELOPER.id]),
    [ROUTES.CREDENTIALS]: USER_ROLE_IDS_LEVEL.FOUR,

    /******************************* Level 3 Roles Routes *****************************/

    /******************************* Level 2 Roles Routes *****************************/

    /******************************* Level 1 Roles Routes *****************************/

    [ROUTES.FLOWS]: ALL_USER_ROLE_IDS,
    [ROUTES.VIEW_FLOW]: ALL_USER_ROLE_IDS,
    [ROUTES.EDIT_FLOW]: ALL_USER_ROLE_IDS,
    [ROUTES.ANALYTICS]: ALL_USER_ROLE_IDS,
    [ROUTES.AUTHENTICATIONS]: ALL_USER_ROLE_IDS,
    [ROUTES.CATALOG]: ALL_USER_ROLE_IDS,


    [ROUTES.WORKSPACE]: [USER_ROLES.ADMIN.id],
    [ROUTES.APP_DETAIL]: [USER_ROLES.ADMIN.id],
    [ROUTES.NEW_FLOW]: ALL_USER_ROLE_IDS,

    /******************************* User Routes *****************************/
    [ROUTES.SSO_LOGIN]: TENANT_ADMIN_USER_ROLE_IDS,

    /******************************* Tenant Admin Routes *****************************/

};

export const DEFAULT_PROFILE_LOGO = "assets/images/default-profile-pic.svg";

export const SHORT_CARD_FIELD_COUNT = 3;
export const KEY_CHAR = ":";

export const FIELD_TYPES = {
    GENERAL: "GENERAL",
    URL: "URL"
};

export const REFRESH_TOKEN_EXPIRED = "REFRESH_TOKEN_EXPIRED";

