import * as uuid from 'uuid';
import md5 from 'blueimp-md5'
import $ from 'jquery'
import CopyToClipbord from 'copy-to-clipboard'
import {
    getAllNodesForPath, getAllPathsForObjectWithPath,
    getAttrValueForEventFromClosest,
    getClosestForEventWithAttribute, setValueForPathOrDefault
} from 'ui-comps/utils/generic-utils'
import Router from '../router/router';
import base64 from 'base-64';
import m from "mithril";
import {getCursorPositionInInput} from "./expr-utils";
import CryptoJS from 'crypto-js';

const R = require('ramda');


const namespace = "1b671a64-40d5-491e-99b0-da01ff1f3341";

export let newUUID = () => uuid.v4();

export const uniqueIdForString = (str) => str && uuid.v5(str, namespace);
export const uniqueIdForObject = (obj) => obj && uniqueIdForString(JSON.stringify(obj));

export let mapFromList = (list, key) => {
    return R.fromPairs(R.map(x => [x[key], x], list));
};

export let isValidEmailId = (emailId) => {
    if(!emailId) return false;
    let reg = /^([A-Za-z0-9_\-\\.])+@([A-Za-z0-9_\-\\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(emailId);
};

export let getQueryStringForUrl = (url) => {
    let qs = (url && url.split("?")[1]) || "";
    return qs.split('#')[0];
};

export let getParamsMapForQueryString = (qs) => {
    let params = qs && qs.split("&");
    const map = {};
    R.forEach((p) => {
        let keys = p.split("=");
        setValueForPathOrDefault(map, keys[0], keys[1]);
    }, params || []);
    return map;
};

export let getAbsoluteUrl = (url) => {
    let u = url && url.split("?")[0];
    return u && u.split("#")[0];
};

export let getHashStringForUrl = (url) => {
    return url && url.split("#")[1];
};

export let generateQueryStringFromParams = (paramMap) => {
    if(!paramMap) return "";

    const nodes = getAllNodesForPath(paramMap, "*");

    return R.reduce(function(acc, node) {
        if(acc.length > 0) acc = acc + "&";
        return acc + R.join(".", node.path) + "=" + node.value;
    }, "", nodes);
};

export let appendParamsAsQueryStringToUrl = (url, paramMap) => {
    let absUrl = getAbsoluteUrl(url);
    let params = getQueryStringForUrl(url) || {};
    paramMap = R.merge(params, paramMap || {});
    let hs = getHashStringForUrl(url);
    let qs = generateQueryStringFromParams(paramMap);
    return absUrl + (qs ? ("?" + qs) : "") + (hs ? ("#" + hs) : "");
};

export let appendParamsAsHashStringToUrl = (url, paramMap) => {
    let absUrl = url.split("#")[0];
    let hs = getHashStringForUrl(url);
    let qs;
    [hs, qs] = hs.split("?");
    let params = getParamsMapForQueryString(qs) || {};
    console.log("absUrl-> " + absUrl + " hs-> " + hs + " params-> " + JSON.stringify(params));
    paramMap = R.mergeLeft(params, paramMap || {});
    qs = generateQueryStringFromParams(paramMap);
    console.log("qs-> " + qs + " paramMap-> " + JSON.stringify(paramMap));

    return absUrl + `#${hs}` + (qs ? ("?" + qs) : "");
};

export let getTrimmedSummaryForFile = (fileSummary) => {

    if(!fileSummary) return;
    let summaryLength = fileSummary.length;
    if(summaryLength <= 55) return fileSummary;
    else return fileSummary.substring(0, 52) + "...";
};

export let getExtensionForFile = (fileName) => {
    let parts = fileName.split(".");
    return parts.length > 1 ? parts.pop() : "";
};

export let getFileNameWithoutExtension = (fileName) => {
    if(!fileName) return;
    // let fileNameWithoutExtsn = fileName.substring(0, fileName.length - getExtensionForFile(fileName).length - 1);
    // let fileNameLength = fileNameWithoutExtsn && fileNameWithoutExtsn.length;
    // if(fileNameLength <= 35) return fileNameWithoutExtsn;
    // else return fileNameWithoutExtsn.substring(0, 32) + "...";
    return fileName && fileName.substring(0, fileName.length - getExtensionForFile(fileName).length - 1);
};

export let getDomainFromEmail = (email) => {
    let temp = email && email.replace(/.*@/, '').split('.');
    return temp[temp.length - 2];
};

export let capitalizeFirstLetter = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export let openUrlInBrowser = (url) => {
    window.openUrlInBrowser && window.openUrlInBrowser(url);
};

export let prepareRESTURI = (uri, params) => {
    if(!params || R.isEmpty(params) || !uri) return uri;
    return R.reduce((acc, k) => {
        let key = ":" + k;
        let value = encodeURIComponent(params[k] || ' ');
        if(acc.endsWith(key)) {
            return acc.substring(0, acc.length - key.length) + value;
        }
        return acc.replace(key + '/', value + "/");
    }, uri, R.keys(params));
};

export let md5Hash = str => str && md5(str);

export let LoadingView = "Loading";
// (
//     <div className="loader max-width-70">
//         <img alt="" src="images/loading.svg"/>
//     </div>
// );

export let copyToClipboard = (text) => {
    CopyToClipbord(text);
};

export let sortTheListByKey = (list, key) => {
    if(!list || R.isEmpty(list)) return list;
    let sorted = R.sortBy(R.prop(key), list);
    return sorted;
};

export let getTrimmedTextOfGivenLength = (text, maxLength) => {
    if(text.length <= maxLength) return text;
    return text.substring(0,maxLength-1) + "...";
};
export let captalizeString = (str) => str && str.substring(0, 1).toUpperCase() + str.substring(1);

export let getContainerFromClosest = (e, attrName) => {
    return $(e.target).closest('[' + attrName + ']');
};

export let openUrlInNewTab = (url) => {

    let elm = document.createElement("a");
    elm.setAttribute("target", "_blank");
    elm.setAttribute("style", "display:none;");
    elm.href = url;
    document.body.appendChild(elm);
    elm.click();

};

// TODO this wont work for keys having .
export let getValueFromObject = (obj, expr) => {
    return R.path(expr.split("."), obj);
};

export let getValueFromObjectOrDefault = (obj, expr, def) => {
    try {
        return getValueFromObject(obj, expr) || def;
    } catch(ex) {}
    return def;
};

export let gotoRoute = (route, params, deepLink, replaceRoute) => route && Router.goToRoute(route, params, deepLink, replaceRoute);
export const goBack = () => Router.goBack();
export let reloadCurrentRoute = () => Router.reloadCurrentRoute();
export let reloadRouteOnEvent = (e) => {
    let route = getAttrValueForEventFromClosest(e, 'data-route');
    return Router.reloadRoute(route);
};

export let goToRouteOnEvent = (e) => {
    let route = getAttrValueForEventFromClosest(e, 'data-route');
    return gotoRoute(route);
};

export let goToRouteOnEventWithDelay = (e) => {
    let delay = Number(getAttrValueForEventFromClosest(e, 'data-delay') || "100");
    return setTimeout(() => goToRouteOnEvent(e), delay);
};

export let download = (filename, content, mimeType = "text/plain") => {
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(content));
    element.setAttribute('download', filename);
    element.setAttribute('class', 'hide');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    // document.body.removeChild(element);
};

export let orderByAsc = (obj, property) => {
    return R.sortWith([R.ascend(R.compose(R.toUpper,R.prop(property)))], obj);
};

export let encodeURIData = (data) => data && encodeURIComponent(base64.encode(escape(JSON.stringify(data))));

export let decodeURIData = (encoded) => encoded && JSON.parse(unescape(base64.decode(decodeURIComponent(encoded))));

export const associate = (fn, list) => R.reduce((acc, obj) => R.assoc(fn(obj), obj, acc), {}, list || []);

export const associateByKey = (key, list) => associate(R.prop(key), list);

export const renderViewForElement = (el, view) => m.render(el, view);

export const setCaretPositionForElement = (elem, caretPos) => {
    if (elem != null) {
        console.log("moving to position: " + caretPos);
        if (elem.createTextRange) {
            const range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        } else {
            if (elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            } else elem.focus();
        }
    }
};

export const onAddOrUpdateDataChange = (e) => {
    if (e.keyCode === 13) {
        e.preventDefault();
        getClosestForEventWithAttribute(e, 'data-form-id')
            .find('#addOrUpdateAction')
            .click();
    }
};

export const focusElement = (element) => {
    const position = getCursorPositionInInput(element);
    return setCaretPositionForElement(element, position);
};

export const applyAutoFocusForContainer = (container) => {
    const focusable = $(container).find(`[data-error="error"]`).first();
    const focusedElement = document.activeElement;
    const isInputOrTextAreaElement = focusedElement && R.includes(focusedElement.tagName, ['INPUT', 'TEXTAREA']);
    if(!focusedElement || !isInputOrTextAreaElement) $(container).find('[autofocus]').first().focus();
    if(focusedElement.getAttribute('data-error') !== "error") focusable.focus();
};

export const BYTE_FORMATTER = {
    TB: {
        label: "TB",
        order: 0
    },
    GB: {
        label: "GB",
        order: 1
    },
    MB: {
        label: "MB",
        order: 2
    },
    KB: {
        label: "KB",
        order: 3
    },
    BYTES: {
        label: "Bytes",
        order: 4
    }
};

export const formatNoOfBytes = (noOfBytes, fromFormatter = BYTE_FORMATTER.TB) => {
    if(fromFormatter.order <= BYTE_FORMATTER.TB.order) {
        const tbs = Number(noOfBytes/1099511627776).toFixed(2);
        if(tbs >= 0.1) return `${tbs} ${BYTE_FORMATTER.TB.label}`;
    }

    if(fromFormatter.order <= BYTE_FORMATTER.GB.order) {
        const gbs = Number(noOfBytes/1073741824).toFixed(2);
        if(gbs >= 0.1) return `${gbs} ${BYTE_FORMATTER.GB.label}`;
    }

    if(fromFormatter.order <= BYTE_FORMATTER.MB.order) {
        const mbs = Number(noOfBytes / 1048576).toFixed(2);
        if (mbs >= 0.1) return `${mbs} ${BYTE_FORMATTER.MB.label}`;
    }

    if(fromFormatter.order <= BYTE_FORMATTER.KB.order) {
        const kbs = Number(noOfBytes/1024).toFixed(2);
        if(kbs >= 0.1) return `${kbs} ${BYTE_FORMATTER.KB.label}`;
    }

    return `${noOfBytes} ${BYTE_FORMATTER.BYTES.label}`;
};

export const arrayBufferToWordArray = (ab) => {
    const i8a = new Uint8Array(ab);
    const a = [];
    for (let i = 0; i < i8a.length; i += 4) {
        a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
    }
    return CryptoJS.lib.WordArray.create(a, i8a.length);
};

export const calculateSHA256ForArrayBuffer = (array) => CryptoJS.SHA256(arrayBufferToWordArray(array)).toString();

export const isOnline = () => !!navigator.onLine;