import $ from 'jquery';
import moment from 'moment';
import * as R from 'ramda';
import React from "react";
import UIBaseComponent from "ui-comps/src/ui-base-component/ui-base-component";
import { formatDateWithFormat, getDateStringWithFormatForTimeInMillis } from "ui-comps/src/utils/date-utils";
import {
    DATA_TYPE,
    getAttrValueForEventFromClosest,
    getAttrValueFromClosest,
    getClosestForElementWithAttribute,
    getClosestForEventWithAttribute,
    getValueForPathOrDefault,
    isValidUrl,
    mapIndexed,
    serializeFormV2, setValueIfEmptyForPathOrDefault,
    VALIDATION_TYPE
} from "ui-comps/utils/generic-utils";
import Endpoints from "../../config/endpoints";
import { formatNoOfBytes, uniqueIdForString } from "../../utils/generic-utils";
import { compileLayoutWithParser, PARSER_TYPE } from "../../utils/template-utils";
import { getStyleClassStringForElement, getStyleForElement, hasClickActions, loadingCircleView, loadingView } from "../../utils/ui-utils";

export const AGGREGATOR_TRANSFORMER = {
    MINUTE: "MINUTE",
    HOUR: "HOUR",
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
    YEAR: "YEAR",
    CUSTOM: "CUSTOM"
};

export const AGGREGATOR_TYPE = {
    COUNT: "COUNT",
    SUM: "SUM",
    AVERAGE: "AVERAGE",
    MAXIMUM: "MAXIMUM",
    MINIMUM: "MINIMUM",
    CUSTOM: "CUSTOM"
};

export const SCALE_TYPE = {
    LINEAR: "linear",
    CATEGORY: "category",
    LOGARITHMIC: "logarithmic",
    RADIAL_LINEAR: "radialLinear",
    TIME: "time",
    TIME_SERIES: "timeseries"
};

export const ELEMENT_TYPE = {
    IMAGE: "IMAGE",
    FORM: "FORM",
    BUTTON: "BUTTON",
    LABEL: "LABEL",
    CONTAINER: "CONTAINER",
    INPUT: "INPUT",
    PASSWORD: "PASSWORD",
    DATETIME_PICKER: "DATETIME_PICKER",
    FILE_UPLOAD: "FILE_UPLOAD",
    SIMPLE_FILE_UPLOAD: "SIMPLE_FILE_UPLOAD",
    IFRAME: "IFRAME",
    HEADER: "HEADER",
    ROW: "ROW",
    COLUMN: "COLUMN",
    BODY: "BODY",
    FOOTER: "FOOTER",
    HEADING: "HEADING",
    LINK: "LINK",
    PARAGRAPH: "PARAGRAPH",
    TEXT: "TEXT",
    TEXT_AREA: "TEXT_AREA",
    FIELD: "FIELD",
    DIVIDER: "DIVIDER",
    FORM_ERRORS: "FORM_ERRORS",
    NEW_LINE: "NEW_LINE",
    PAGE: "PAGE",
    TABLE: "TABLE",
    TABLE_HEAD: "TABLE_HEAD",
    TABLE_HEAD_COLUMN: "TABLE_HEAD_COLUMN",
    TABLE_BODY: "TABLE_BODY",
    TABLE_ROW: "TABLE_ROW",
    TABLE_COLUMN: "TABLE_COLUMN",
    UL: "UL",
    LIST: "LIST",
    LIST_ITEM: "LIST_ITEM",
    LI: "LI",
    CHECKBOX: "CHECKBOX",
    RADIO: "RADIO",
    DROP_DOWN: "DROP_DOWN",
    BAR_CHART: "BAR_CHART",
    LINE_CHART: "LINE_CHART",
    PIE_CHART: "PIE_CHART",
    DOUGHNUT_CHART: "DOUGHNUT_CHART"
};

export const CHART_TYPE = {
    BAR_CHART: "bar",
    LINE_CHART: "line",
    PIE_CHART: "pie",
    DOUGHNUT_CHART: "doughnut"
};

export const VALUE_FORMATTER = {
    DATE: "DATE"
};

export const CHECKBOX_TYPE = {
    CHECKBOX: "checkbox",
    RADIO: "radio"
};

export const ELEMENTS_WITH_TAB_INDEX = [ELEMENT_TYPE.BUTTON, ELEMENT_TYPE.INPUT, ELEMENT_TYPE.PASSWORD, ELEMENT_TYPE.LINK, ELEMENT_TYPE.FILE_UPLOAD, ELEMENT_TYPE.SIMPLE_FILE_UPLOAD, ELEMENT_TYPE.FIELD, ELEMENT_TYPE.DATETIME_PICKER];

export const HEADING = {
    H1: "H1",
    H2: "H2",
    H3: "H3",
    H4: "H4",
    H5: "H5",
    H6: "H6"
};

export const FIELD = {
    TEXT: "TEXT",
    PASSWORD: "PASSWORD",
    TEXT_AREA: "TEXT_AREA",
    CHECKBOX: "CHECKBOX",
    RADIO: "RADIO",
    DROP_DOWN: "DROP_DOWN",
    PARAGRAPH: "PARAGRAPH",
    LINK: "LINK",
    FILE_UPLOAD: "FILE_UPLOAD",
    SIMPLE_FILE_UPLOAD: "SIMPLE_FILE_UPLOAD",
    DATETIME_PICKER: "DATETIME_PICKER"
};

export const FORM = {
    EQUAL_COLUMNS_2: "EQUAL_COLUMNS_2"
};

export const BUTTON = {
    PRIMARY: "PRIMARY",
    SECONDARY: "SECONDARY",
    BASIC: "BASIC"
};

export const REPEATER_TYPE = {
    DATA: "DATA",
    TILL_COUNT: "TILL_COUNT"
};

export const ACTION_TYPE = {
    OPERATION: "OPERATION",
    SUBMIT_FORM: "SUBMIT_FORM",
    CLEAR_FORM: "CLEAR_FORM",
    RESET_FORM: "RESET_FORM",
    FILE_DOWNLOAD: "FILE_DOWNLOAD",
    GO_TO_PAGE: "GO_TO_PAGE",
    GO_BACK: "GO_BACK",
    OPEN_URL: "OPEN_URL",
    SYNC_STATE: "SYNC_STATE",
    SET_PAGE_STATE: "SET_PAGE_STATE",
    UNSET_PAGE_STATE: "UNSET_PAGE_STATE",
    SCRIPT: "SCRIPT"
};

const elementContainer = { "data-element-container": true };

export default class DynamicComponentView extends UIBaseComponent {

    getLayout = () => this.state.layoutConfig || this.props.page.layoutConfig;

    getDataSources = () => this.props.page.dataSources;

    beforeRender() {
        this.subPages = {};
        this.elementsById = {};
        this.state = {
            dataLoaded: true,
            localFiles: {},
            chunkedUpload: {},
            pageData: {}
        };
        this.charts = this.charts || {};
        this.loadData();
        return super.beforeRender();
    }

    loadData() {
        const styles = this.props.page.styles || "";
        if(styles) {

            const styleId = `page-${this.props.page._id}`;

            const style = `<style id="${styleId}" type="text/css">${styles}</style>`;
            const $head = $('head');
            const $style = $head.find('#' + styleId);

            if($style.length === 0) $head.append(style);
            else $style.replaceWith(style);
        }
    }

    getElementById = (id) => this.elementsById[id];

    getView() {
        return super.getView()
            .then(_ => {

                if (!this.state.dataLoaded) return loadingView();

                const data = this.getCompiledData();
                const clickHandler = this.buttonOnClick && this.buttonOnClick.bind(this, data);
                const changeHandler = this.onFieldChange && this.onFieldChange.bind(this, data);
                const lc = R.clone(this.props.page.layoutConfig || {});
                return this.prepareView(lc, "", data, clickHandler, changeHandler);
            });
    }

    getCompiledData = () => {
        const pageForm = serializeFormV2(this.$container);
        const pageData = this.state.pageData || {};
        return R.mergeLeft(R.mergeLeft({pageData, pageForm}, pageData), this.state.compiledData || {});
    };

    preparePathForChildElement = (parentPath, elemIndex) => parentPath ? `${parentPath}.elements.${elemIndex}` : `elements.${elemIndex}`;

    isActionRunning = (elementId) => this.state.runningActions && this.state.runningActions[elementId];
    getRunningClassForButton = (elementId) => this.isActionRunning(elementId) ? "loading disabled" : "";

    getFileUploadView = (element, elementPath = "", containerAttributes) => {
        // const tabindex = getTabIndexForElement(element, elementPath + '.AddFiles');
        let customAttributes = {};
        // if(tabindex) customAttributes = {tabindex};

        const chooseFileView = (
            <div style="text-align: center; margin-top: 60px">
                <span style="width: 80px; cursor: pointer !important; color: #007eb0">{element.placeHolder}</span>
                <input {...customAttributes} data-file-type="LOCAL" type="file" style="
                                    left: 39%;
                                    max-width: 80px;
                                    border: none !important;
                                    z-index: 1;
                                    padding: 0px !important;
                                    overflow: hidden;
                                    opacity: 0;
                                    position: absolute;
                                    cursor: pointer !important;" data-required={element.required || ""} data-elem-path={elementPath} accept={element.acceptFiles} onchange={this.onLocalFilesChanged}
                       multiple={element.allowMultiple} data-key={element.key} placeholder={this.placeholderForElement(element)}/>
            </div>
        );

        const files = mapIndexed((f, index) => {
            let iconUrl = "default-file.svg";
            if(!R.isNil(f.percentageCompleted)) {
                if(f.percentageCompleted === 100) iconUrl = "success.svg";
                else iconUrl = "uploading.svg";
            }
            iconUrl = f.uploadFailed ? "failed.svg" : iconUrl;
            const nameColor = f.uploadFailed ? "#EF144F" : "#333333";
            const uploading = !f.uploadFailed && !R.isNil(f.percentageCompleted) && f.percentageCompleted < 100;

            // const tabindex = getTabIndexForElement(element, elementPath + `.${index}` + '.DeleteFile');
            let customAttributes = {};
            // if(tabindex) customAttributes = {tabindex};

            return (
                <div data-elem-path={elementPath} data-file-index={index} style="border-bottom: 0.5px solid #B7B7B7; min-height: 32px;">

                    <div style="position: relative; top: 7px;">

                        <span style="float: left; font-size: 12px !important; color: #333333">
                            <img src={Endpoints.getCDNUrl("images/file-upload/" + iconUrl)} style="margin-right: 2px; vertical-align: middle; min-width: 11px;" />
                        </span>

                        <span style={`overflow: hidden;max-width: calc(100% - 112px);white-space: nowrap;text-overflow: ellipsis;float: left;font-size: 12px !important;padding-left: 10px;color: ${nameColor};`}>{f.name}</span>

                        <span {...customAttributes} style="float: right !important" class={this.state.formSubmitted ? "hide" : ""}>
                            <img src={Endpoints.getCDNUrl("images/delete.svg")} style="cursor: pointer" onclick={this.deleteLocalFile}/>
                        </span>

                        <span style="float: right !important; margin-left: 8px; margin-right: 8px; font-size: 12px;">{formatNoOfBytes(f.size)}</span>

                    </div>

                    <div class={uploading ? "" : "hide"} style="clear: both; padding-left: 23px; position: relative; text-align: left; padding-top: 0px;">
                        <span style="font-size: 9px;">{f.percentageCompleted}%</span>
                        <span style="position: relative; width: 70%; display: inline-block; padding-left: 3px;">
                            <span style={`background-color: #61b581;width: ${f.percentageCompleted}% ;z-index: 1;margin: 0;border-radius: 20px;position: absolute;height: 5px;display: block;`}/>
                            <span style="background-color: #d4d4d4; width: 100%; margin: 0; border-radius: 20px; height: 5px; display: block;"/>
                        </span>
                    </div>

                </div>
            );
        }, this.state.localFiles[element.key] || []);

        const filesView = (
            <div style="padding: 14px 0px 14px 14px">
                <h4 style="font-size: 13px !important; font-weight: 400 !important; margin-bottom: 10px">{element.filesSelectedLabel || ""}</h4>
                <div style="max-height: 200px; overflow: hidden; overflow-y: auto; padding-right: 15px;">
                    {files}
                </div>
                <p class={this.state.formSubmitted ? "hide" : ""}>
                    <a {...customAttributes} style="cursor: pointer; font-size: 12px; padding-top: 6px; clear: both; display: block; color: #4183c4; text-decoration: none; max-width: 100px">
                        <span style="width: 80px; cursor: pointer !important; color: #007eb0">{element.addMoreFilesLabel}</span>
                        <input data-file-type="LOCAL" type="file" style="
                            left: 0%;
                            border: none !important;
                            z-index: 1;
                            padding: 0px !important;
                            overflow: hidden;
                            opacity: 0;
                            position: absolute;
                            cursor: pointer !important;" data-required={element.required || ""} data-elem-path={elementPath} accept={element.acceptFiles}
                               onchange={this.onLocalFilesChanged}
                               multiple={element.allowMultiple} data-key={element.key}
                               placeholder={this.placeholderForElement(element)}/>
                    </a>
                </p>
            </div>
        );

        const view = R.isEmpty(this.state.localFiles) ? chooseFileView : filesView;

        return [
            <div {...containerAttributes} data-elem-path={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                {view}
                <div class="hide">
                    <img src={Endpoints.getCDNUrl("images/file-upload/default-file.svg")}  alt=""/>
                    <img src={Endpoints.getCDNUrl("images/file-upload/failed.svg")}   alt=""/>
                    <img src={Endpoints.getCDNUrl("images/file-upload/success.svg")}  alt="" />
                    <img src={Endpoints.getCDNUrl("images/file-upload/uploading.svg")}  alt="" />
                </div>
            </div>,
            this.getErrorsForField(element)
        ];
    };

    showPasswordValue = (e) => {
        const $container = getClosestForEventWithAttribute(e, "data-field-container");
        const $el = $container.find('input');
        const $image = $container.find('[data-show-password-icon]');
        if($el.attr('type') === "password") {
            $el.attr('type', 'text');
            $image.attr('src', $image.attr('data-show-url'));
        } else {
            $el.attr('type', 'password');
            $image.attr('src', $image.attr('data-hide-url'));
        }
    };

    prepareRepeaterView = (element, elementPath = "", compiledData, clickHandler, changeHandler) => {
        const itemElement = R.omit(["repeater", "repeatView"], element);
        return this.prepareView(itemElement, elementPath, compiledData, clickHandler, changeHandler);
    };

    compileElement = (element, compiledData) => element;

    hideElement = (element) => false;

    prepareView = (element, elementPath = "", compiledData, clickHandler, changeHandler) => {

        if(!element) return;

        if(element.repeatView) return this.prepareRepeaterView(element, elementPath, compiledData, clickHandler, changeHandler);

        element = this.compileElement(element, compiledData);

        if(this.hideElement(element)) return;

        if(element.id) this.elementsById[element.id] = element;

        const elementId = element.id || uniqueIdForString(elementPath);

        // const tabindex = getTabIndexForElement(element, elementPath);
        let customAttributes = {};

        let clickAttributes = null;

        if(hasClickActions(element)) {
            clickAttributes = { "data-action": !!clickHandler, "onclick": clickHandler };
        }
        
        // if(tabindex) customAttributes = {tabindex};
        if(element.valueDataType) customAttributes['data-type'] = element.valueDataType;

        const elemValue = this.getValueForElement(element, elementPath) || element.defaultValue || "";

        const children = mapIndexed((e, index) => this.prepareView(e, this.preparePathForChildElement(elementPath, index), compiledData, clickHandler, changeHandler), element.elements || []);

        let view = children;

        let containerAttributes = this.prepareContainerAttributes(element, elementPath);

        // console.log("container: ", element.type, containerAttributes);

        if(R.includes(element.type, [ELEMENT_TYPE.BAR_CHART, ELEMENT_TYPE.LINE_CHART, ELEMENT_TYPE.PIE_CHART, ELEMENT_TYPE.DOUGHNUT_CHART])) {
            const style = "position: relative; " + getStyleForElement(element);
            view = (
                <div {...containerAttributes} {...customAttributes} style={style} class={getStyleClassStringForElement(element)}>
                    <canvas id={element.id || elementPath} data-elem-path={elementPath} />
                </div>
            );
        } else if (element.type === ELEMENT_TYPE.BUTTON) {

            const typeClass = (element.subType || "").toLowerCase();

            view = <button {...containerAttributes} {...customAttributes} id={element.id || elementPath} data-action={!!clickHandler} class={getStyleClassStringForElement(element, " ui button " + this.getRunningClassForButton(element.id))}
                           data-elem-path={elementPath} style={getStyleForElement(element)} onclick={clickHandler}>{this.getValueForElement(element, elementPath)}</button>;

        } else if (element.type === ELEMENT_TYPE.IMAGE) {

            const url = element.url || element.assetId;
            const imageUrl = url ? (isValidUrl(url) ? url : Endpoints.APP_ASSET_DOWNLOAD_URL(url)) : Endpoints.getCDNUrl("images/image-icon.svg");

            view = <img {...containerAttributes} {...customAttributes} {...clickAttributes} id={elementPath} data-elem-path={elementPath} src={imageUrl} alt=""
            class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}/>;

        } else if (element.type === ELEMENT_TYPE.INPUT) {

            view = (
                <input {...containerAttributes} {...customAttributes} data-required={element.required || ""} data-validation-type={element.valueType || ""} oninput={this.getFieldChangeHandler(element, changeHandler)} data-elem-path={elementPath} readOnly={element.readOnly} data-key={element.key}
                       placeholder={this.placeholderForElement(element)} defaultValue={elemValue} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}/>
            );

        } else if (element.type === ELEMENT_TYPE.PASSWORD) {

            view = (
                <div {...containerAttributes} id={elementPath} data-field-container="true">
                    <div>{element.label}</div>
                    <input {...customAttributes} data-elem-path={elementPath} type="password" data-key={element.key} data-required={element.required || ""} data-validation-type={element.valueType || ""}
                           placeholder={this.placeholderForElement(element)} defaultValue={elemValue} oninput={this.getFieldChangeHandler(element, changeHandler)}/>
                    <span style="position: relative;" onclick={this.showPasswordValue}>
                        <img data-show-password-icon={true} data-show-url={Endpoints.getCDNUrl("images/show-icon.svg")} data-hide-url={Endpoints.getCDNUrl("images/hide-icon.svg")} src={Endpoints.getCDNUrl("images/hide-icon.svg")} style="position: absolute; right: 7px; top: 6px; cursor: pointer; max-width: 17px;" />
                    </span>
                </div>
            );

        } else if (element.type === ELEMENT_TYPE.DATETIME_PICKER) {

            view = (      
                <div class="ui calendar" {...containerAttributes} id={element.id || elementPath} data-field-container="true" >
                    <div class="ui input left icon">
                        <i class="calendar icon" />
                        <input {...customAttributes} data-elem-path={elementPath} type="text" data-key={element.key}
                            placeholder={this.placeholderForElement(element) || "Date"} oninput={this.getFieldChangeHandler(element, changeHandler)} />
                    </div>
                </div>
            );

        } else if (element.type === ELEMENT_TYPE.FORM) {

            let claz = "";

            if(element.subType === FORM.EQUAL_COLUMNS_2) claz = "equal-columns-2";

            view = (
                <div {...containerAttributes} id={elementPath} className="kit-wrapper">
                    <div class={getStyleClassStringForElement(element, "ui segment")} style={getStyleForElement(element)}>
                        <div data-elem-path={elementPath} data-show-progress={!!element.showProgress} data-form={elemValue} className={"ui form " + claz}>
                            {children}
                        </div>
                    </div>
                </div>
            );
        } else if (element.type === ELEMENT_TYPE.FILE_UPLOAD) {

            view = this.getFileUploadView(element, elementPath, containerAttributes);

        } else if (element.type === ELEMENT_TYPE.SIMPLE_FILE_UPLOAD) {
            view = (
                <p {...containerAttributes} class={getStyleClassStringForElement(element, "choose-file-wrapper")} style={getStyleForElement(element)}>
                    <span className="choose-file" style={element.labelStyles || ""}>{element.pickerLabel}</span>
                    <input {...customAttributes} style={element.inputStyles || ""} data-required={element.required || ""}
                           data-validation-type={element.valueType || ""} data-elem-path={elementPath} type="file"
                           accept={element.acceptFiles} onchange={changeHandler}
                           multiple={element.allowMultiple} data-key={element.key}
                           placeholder={this.placeholderForElement(element)}/>
                </p>
            );

        } else if (element.type === ELEMENT_TYPE.LABEL) {
            view = (
                <label {...containerAttributes} id={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    {elemValue}
                </label>
            );
        } else if (element.type === ELEMENT_TYPE.IFRAME) {

            let attributes = {};

            if (element.content) attributes.srcDoc = element.content;
            if (element.contentUrl) attributes.src = element.contentUrl;

            view = (
                <iframe {...containerAttributes} id={elementPath} {...attributes} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)} />
            );
        } else if (element.type === ELEMENT_TYPE.HEADER) {

            view = (
                <div {...containerAttributes} id={elementPath} class={getStyleClassStringForElement(element, "header")} style={getStyleForElement(element)}>
                    {children}
                </div>
            );
        } else if (element.type === ELEMENT_TYPE.ROW) {

            view = (
                <div {...containerAttributes} id={elementPath} class={getStyleClassStringForElement(element, "ui row")}
                     style={getStyleForElement(element)}>
                    {children}
                </div>
            );
        } else if (element.type === ELEMENT_TYPE.COLUMN) {
            const style = getStyleForElement(element);
            const styles = style.split(";");
            const widthStyle = R.find(s => R.startsWith("width:", s), styles) || "";
            const segmentStyles = widthStyle ? R.join(";", R.reject(s => R.startsWith("width:", s), styles)) : style;
            view = (
                <div {...containerAttributes} id={elementPath} className="wide column" style={widthStyle}>
                    <div class={getStyleClassStringForElement(element, "ui segment")} style={segmentStyles}>
                        {children}
                    </div>
                </div>
            );
        } else if (element.type === ELEMENT_TYPE.BODY) {

            view = (
                <div {...containerAttributes} id={elementPath} class={getStyleClassStringForElement(element, "body-content")} style={getStyleForElement(element)}>
                    {children}
                </div>
            );
        } else if (element.type === ELEMENT_TYPE.FOOTER) {

            view = (
                <div {...containerAttributes} id={elementPath} class={getStyleClassStringForElement(element, "footer")} style={getStyleForElement(element)}>
                    {children}
                </div>
            );
        } else if (element.type === ELEMENT_TYPE.HEADING) {

            if(element.subType === HEADING.H2) {
                view = (
                    <h2 {...containerAttributes} {...clickAttributes} id={elementPath} class={getStyleClassStringForElement(element, "ui header")} style={getStyleForElement(element)}>{elemValue}</h2>
                );
            } else if(element.subType === HEADING.H3) {
                view = (
                    <h3 {...containerAttributes} {...clickAttributes} id={elementPath} class={getStyleClassStringForElement(element, "ui header")} style={getStyleForElement(element)}>{elemValue}</h3>
                );
            } else if(element.subType === HEADING.H4) {
                view = (
                    <h4 {...containerAttributes} {...clickAttributes} id={elementPath} class={getStyleClassStringForElement(element, "ui header")} style={getStyleForElement(element)}>{elemValue}</h4>
                );
            } else if(element.subType === HEADING.H5) {
                view = (
                    <h5 {...containerAttributes} {...clickAttributes} id={elementPath} class={getStyleClassStringForElement(element, "ui header")} style={getStyleForElement(element)}>{elemValue}</h5>
                );
            } else if(element.subType === HEADING.H6) {
                view = (
                    <h6 {...containerAttributes} {...clickAttributes} id={elementPath} class={getStyleClassStringForElement(element, "ui header")} style={getStyleForElement(element)}>{elemValue}</h6>
                );
            } else {
                view = (
                    <h1 {...containerAttributes} {...clickAttributes} id={elementPath} class={getStyleClassStringForElement(element, "ui header")} style={getStyleForElement(element)}>{elemValue}</h1>
                );
            }

        } else if(element.type === ELEMENT_TYPE.LINK) {

            const running = this.isActionRunning(element.id);
            const handler = elemValue ? null : (running ? null : clickHandler);
            const attributes = {};
            if(elemValue) attributes.href = elemValue;

            const loadingView = running ? loadingCircleView() : null;

            view = [
                <a {...containerAttributes} {...customAttributes} data-action={!!handler} class={getStyleClassStringForElement(element, this.getRunningClassForButton(element.id))} id={element.id || elementPath} {...attributes} data-elem-path={elementPath} style={getStyleForElement(element)} onclick={handler}>{children}</a>,
                loadingView
            ];
        } else if(element.type === ELEMENT_TYPE.PARAGRAPH) {
            view = (
                <p {...containerAttributes} {...clickAttributes} id={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>{R.isEmpty(children) ? elemValue : children}</p>
            );
        } else if(element.type === ELEMENT_TYPE.TEXT) {
            view = <span {...containerAttributes} {...clickAttributes} id={element.id || elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element) + "; clear: both"}>{elemValue}</span>;
            // view = elemValue;
        } else if(element.type === ELEMENT_TYPE.TEXT_AREA) {
            // view = <div {...containerAttributes} id={element.id || elementPath} style={getStyleForElement(element)}>{elemValue}</div>;
            view = (
                <div {...containerAttributes} {...clickAttributes} id={element.id || elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    <textarea {...customAttributes} data-required={element.required || ""} data-validation-type={element.valueType || ""} data-elem-path={elementPath} data-key={element.key}>{elemValue}</textarea>
                </div>
            );

        } else if(element.type === ELEMENT_TYPE.NEW_LINE) {
            view = <br {...containerAttributes} id={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}/>;
        } else if(element.type === ELEMENT_TYPE.DIVIDER) {
            view = (
                <div {...containerAttributes} id={elementPath} class={getStyleClassStringForElement(element, "ui divider")} style={getStyleForElement(element)}/>
            );
        } else if(element.type === ELEMENT_TYPE.FORM_ERRORS) {

            view = this.getFormErrorsView(element, elementPath);

        } else if(element.type === ELEMENT_TYPE.FIELD) {

            let fieldView;
            let attributes = {};
            // if(element.autoFocus) attributes.autofocus = true;
            if(element.readOnly) attributes.readonly = true;

            if(element.subType === FIELD.DROP_DOWN) {

                fieldView = this.prepareView(R.assoc('type', FIELD.DROP_DOWN, element), elementPath, compiledData, clickHandler, changeHandler);

            } else if(element.subType === FIELD.DATETIME_PICKER) {

                fieldView = this.prepareView(R.assoc('type', FIELD.DATETIME_PICKER, element), elementPath, compiledData, clickHandler, changeHandler);

            } else if(element.subType === FIELD.CHECKBOX) {

                fieldView = this.getCheckboxViewForElement(element, elementPath, elemValue, containerAttributes, customAttributes, element.subType, changeHandler);

            } else if(element.subType === FIELD.RADIO) {

                fieldView = this.getCheckboxViewForElement(element, elementPath, elemValue, containerAttributes, customAttributes, element.subType, changeHandler);

            } else if(element.subType === FIELD.PASSWORD) {
                fieldView = [
                    <input {...customAttributes} oninput={this.getFieldChangeHandler(element, changeHandler)} data-required={element.required || ""} data-validation-type={element.valueType || ""} type="password" {...attributes} data-elem-path={elementPath}
                           data-key={element.key}
                           placeholder={this.placeholderForElement(element)} defaultValue={elemValue} onkeyup={this.onInputKeyUp} />,
                    <span style="position: relative;" onclick={this.showPasswordValue}>
                        <img data-show-password-icon={true}  data-show-url={Endpoints.getCDNUrl("images/show-icon.svg")} data-hide-url={Endpoints.getCDNUrl("images/hide-icon.svg")}  src={Endpoints.getCDNUrl("images/hide-icon.svg")} style="position: absolute; right: 7px; top: 6px; cursor: pointer; max-width: 17px;" />
                    </span>
                ];
            } else if(element.subType === FIELD.PARAGRAPH) {
                fieldView = (
                    <p data-elem-path={elementPath} id={elementPath}>{R.isEmpty(children) ? elemValue : children}</p>
                );
            } else if(element.subType === FIELD.TEXT_AREA) {

                fieldView = (
                    <textarea {...customAttributes} oninput={this.getFieldChangeHandler(element, changeHandler)} data-required={element.required || ""} data-validation-type={element.valueType || ""} data-elem-path={elementPath} data-key={element.key}>{elemValue}</textarea>
                );
            } else if(element.subType === FIELD.LINK) {
                const running = this.isActionRunning(element.id);
                const handler = elemValue ? null : (running ? null : clickHandler);
                const attributes = {};
                if(elemValue) attributes.href = elemValue;

                const loadingView = running ? loadingCircleView() : null;

                fieldView = [
                    <a id={element.id || elementPath} data-action={!!handler} class={getStyleClassStringForElement(element, this.getRunningClassForButton(element.id))} {...customAttributes} {...attributes} data-elem-path={elementPath} style={getStyleForElement(element)} onclick={handler}>{children}</a>,
                    loadingView
                ];
            } else if(element.subType === FIELD.SIMPLE_FILE_UPLOAD) {
                const selectedFileNames = this.getSelectedFileNamesForElement(elementPath);
                const pickerName = R.isEmpty(selectedFileNames) ? element.pickerLabel : selectedFileNames.join(", ");
                fieldView = (
                    <p  {...containerAttributes} class={getStyleClassStringForElement(element, "choose-file-wrapper")} style={getStyleForElement(element)}>
                        <span className="choose-file" style={element.labelStyles || ""}>{pickerName}</span>
                        <input {...customAttributes} style={element.inputStyles || ""} data-required={element.required || ""} data-validation-type={element.valueType || ""} data-elem-path={elementPath} type="file" accept={element.acceptFiles} onchange={this.onFilesChanged}
                               multiple={element.allowMultiple} data-key={element.key} placeholder={this.placeholderForElement(element)}/>
                    </p>
                );
            } else {
                fieldView = (
                    <input {...customAttributes} oninput={this.getFieldChangeHandler(element, changeHandler)} data-required={element.required || ""} data-validation-type={element.valueType || ""} type="text" {...attributes} data-elem-path={elementPath}
                           data-key={element.key}
                           placeholder={this.placeholderForElement(element)} defaultValue={elemValue} onkeyup={this.onInputKeyUp} />
                );
            }

            view = (
                <div {...containerAttributes} {...clickAttributes} data-field-container="true" id={elementPath} class={getStyleClassStringForElement(element, "field")} style={getStyleForElement(element)}>
                    <label>{element.label}</label>
                    {fieldView}
                    {this.getErrorsForField(element)}
                </div>
            );

        } else if(element.type === ELEMENT_TYPE.CONTAINER) {
            view = (
                <div {...containerAttributes} {...clickAttributes} id={elementPath} class={getStyleClassStringForElement(element, "custom-container")} style={getStyleForElement(element)}>
                    {children}
                </div>
            );
        } else if(element.type === ELEMENT_TYPE.PAGE) {
            view = (
                <div {...containerAttributes} {...clickAttributes} data-sub-page={elementPath} id={`PAGE-${elementId}`} data-elem-path={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}/>
            );
        } else if(element.type === ELEMENT_TYPE.TABLE) {
            const classString = getStyleClassStringForElement(element, "ui celled table" + (element.haveBorder ? "" : " no-border") + (element.stackable ? "" : " unstackable"));
            view = (
                <table {...containerAttributes} {...clickAttributes} class={classString} style={getStyleForElement(element)}>
                    {children}
                </table>
            );
        } else if(element.type === ELEMENT_TYPE.TABLE_HEAD) {
            view = (
                <thead {...containerAttributes} {...clickAttributes} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                {children}
                </thead>
            );
        } else if(element.type === ELEMENT_TYPE.TABLE_HEAD_COLUMN) {
            view = (
                <th {...containerAttributes} {...clickAttributes} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    {R.isEmpty(children) ? elemValue : children}
                </th>
            );
        } else if(element.type === ELEMENT_TYPE.TABLE_BODY) {
            view = <tbody {...containerAttributes} {...clickAttributes} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
            {children}
            </tbody>
        } else if(element.type === ELEMENT_TYPE.TABLE_ROW) {
            view = (
                <tr {...containerAttributes} {...clickAttributes} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    {children}
                </tr>
            );
        } else if(element.type === ELEMENT_TYPE.TABLE_COLUMN) {
            view = (
                <td {...containerAttributes} {...clickAttributes} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    {R.isEmpty(children) ? elemValue : children}
                </td>
            );
        } else if(element.type === ELEMENT_TYPE.UL) {
            view = this.getULView(element, elementPath, compiledData, clickHandler, changeHandler);
        } else if(element.type === ELEMENT_TYPE.LIST) {
            view = (
                <ul {...containerAttributes} {...clickAttributes} id={element.id || elementPath} data-elem-path={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    {children}
                </ul>
            );
        } else if(element.type === ELEMENT_TYPE.LIST_ITEM) {
            view = (
                <li {...containerAttributes} {...clickAttributes} id={element.id || elementPath} data-elem-path={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    {children}
                </li>
            );
        } else if(element.type === ELEMENT_TYPE.LI) {
            view = (
                <li {...containerAttributes} {...clickAttributes} id={element.id || elementPath} data-elem-path={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    {elemValue}
                </li>
            );
        } else if(element.type === ELEMENT_TYPE.CHECKBOX) {

            view = this.getCheckboxViewForElement(element, elementPath, elemValue, containerAttributes, customAttributes, element.type, changeHandler);

        } else if(element.type === ELEMENT_TYPE.RADIO) {

            view = this.getCheckboxViewForElement(element, elementPath, elemValue, containerAttributes, customAttributes, element.type, changeHandler);

        } else if(element.type === ELEMENT_TYPE.DROP_DOWN) {

            const option = (element.options || [])[0];
            const viewPreparer = Array.isArray(option) ? this.getDropdownViewForTuple : (typeof(option) === "object" ? this.getDropdownViewForLabelValue : this.getDropdownViewForValues);

            view = viewPreparer({
                dropdownList: element.options,
                selectedValue: elemValue,
                inputDataKey: element.key,
                onChangeHandler: changeHandler,
                containerId: element.key,
                multiSelect: element.multiSelect,
                fieldRequired: element.required,
                elementPath
                // validationType,
                // validationError,
                // dataType
            });

            view = (
                <div {...containerAttributes} {...clickAttributes} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                    {view}
                </div>
            );
        }

        return view;
    };

    getULView = (element, elementPath, data, clickHandler, changeHandler) => {
        // TODO dynamic
        if(element.dynamic) throw new Error("Yet to Implement");
        const childViews = mapIndexed((el, index) => this.prepareView(el, this.preparePathForChildElement(elementPath, index), data, clickHandler, changeHandler), element.elements || []);

        const containerAttributes = this.prepareContainerAttributes(element, elementPath);

        return (
            <ul {...containerAttributes} id={element.id || elementPath} data-elem-path={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                {childViews}
            </ul>
        );
    };

    hasOnChangeActions = (el) => el.onChange && el.onChange.actions && !R.isEmpty(el.onChange.actions);
    getFieldChangeHandler = (el, changeHandler) => this.hasOnChangeActions(el) ? changeHandler : null;

    getCheckboxViewForElement = (element, elementPath, elemValue, containerAttributes, customAttributes, elementType, changeHandler) => {
        // type = type || CHECKBOX_TYPE.CHECKBOX;
        // const type = element.multiSelect ? CHECKBOX_TYPE.CHECKBOX : CHECKBOX_TYPE.RADIO;
        const type = elementType === ELEMENT_TYPE.CHECKBOX ? CHECKBOX_TYPE.CHECKBOX : CHECKBOX_TYPE.RADIO;
        const view = R.map(option => {
            const checkedAttribute = (element.multiSelect && Array.isArray(elemValue) ? R.includes(option.value, elemValue || []) : elemValue === option.value) ? {checked: true} : null;
            return (
                <div className={"ui checkbox" + (elementType === ELEMENT_TYPE.RADIO ? " radio" : "")}>
                    <input name={element.key} {...customAttributes} data-required={element.required || ""} data-validation-type={element.valueType || ""} type={type} {...checkedAttribute} data-elem-path={elementPath}
                           data-key={element.key} data-multi-value={element.multiSelect || false}
                           placeholder={this.placeholderForElement(element)} value={option.value} onchange={this.getFieldChangeHandler(element, changeHandler)}/>
                    <label>{option.label}</label>

                </div>
            );
        }, element.options || [{label: element.label, value: element.value}]);

        return (
            <div class={getStyleClassStringForElement(element, "checkbox-container")} {...containerAttributes} style={getStyleForElement(element)}>
                {view}
            </div>
        );
    };

    onInputKeyUp = (e) => {
        if(e.keyCode === 13) {

            let $defaultActionEl = null;

            const elementId = getAttrValueForEventFromClosest(e, 'data-element-container-id');
            const element = this.getElementById(elementId);
            if(element && element.defaultActionId) {
                $defaultActionEl = this.$container.find(`#${element.defaultActionId}`);
            } else {
                const $form = getClosestForEventWithAttribute(e, 'data-form');
                const formPath = getAttrValueFromClosest($form, "data-elem-path");
                const formElement = getValueForPathOrDefault(this.getLayout(), formPath);
                const defaultActionId = formElement && formElement.defaultActionId;
                $defaultActionEl = defaultActionId && $form.find(`#${defaultActionId}`);
            }

            if($defaultActionEl && $defaultActionEl.length > 0) {
                e.preventDefault();
                $defaultActionEl.click();
            }
        }
    };

    getTableView = (element, elementPath, data, clickHandler, changeHandler) => {

        const getColumnsView = (rowData) => {
            return mapIndexed((c, index) => {
                const column = compileLayoutWithParser(R.clone(c), rowData);
                const elements = this.prepareView(column, this.preparePathForChildElement(elementPath, index), data, clickHandler, changeHandler);
                return (
                    <div className="wide column">
                        <div class={getStyleClassStringForElement(element, "ui segment")} style={getStyleForElement(column)}>
                            {elements}
                        </div>
                    </div>
                );
            }, element.columns);
        };

        const rows = R.map(d => {
            const rowData = R.assoc(element.rowRef, d, data);
            return (
                <div className="ui row" style={element.rowStyle || ""}>
                    {getColumnsView(rowData)}
                </div>
            );
        }, element.data);

        const containerAttributes = this.prepareContainerAttributes(element, elementPath);

        return (
            <div {...containerAttributes} id={elementPath} class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                {rows}
            </div>
        )
    };

    getErrorView = (error) => {
        if(!error || R.isEmpty(error)) return;
        return (
            <p id={`ERROR-${error.code}`} style="color: red; margin-top: 4px; font-size: 12px !important;" data-error-code={error.code}>{error.description}</p>
        );
    };

    getFormErrorsView = (element, elementPath) => {
        let formErrors = getValueForPathOrDefault(this.state, "errors.formErrors", []);
        if(R.isEmpty(formErrors) && element.defaultFormErrorForAnyFieldError) {
            const hasClientErrors = this.state.clientErrors && !R.isEmpty(this.state.clientErrors);
            const fieldErrors = getValueForPathOrDefault(this.state, "errors.fieldErrors", {});
            if(hasClientErrors || !R.isEmpty(fieldErrors)) {
                formErrors.push({code: "DEFAULT", description: element.defaultFormErrorForAnyFieldError});
            }
        }
        const errors = R.map(this.getErrorView, formErrors);

        let containerAttributes = this.prepareContainerAttributes(element, elementPath);

        return (
            <div {...containerAttributes} id="form-errors" class={getStyleClassStringForElement(element)} style={getStyleForElement(element)}>
                {errors}
            </div>
        );
    };

    prepareContainerAttributes = (element, elementPath) => {
        const containerId = element.id || uniqueIdForString(elementPath);

        let containerAttributes = R.mergeLeft({"key": containerId, "data-element-container-id": containerId, "data-element-container-path": elementPath, "data-element-type": element.type}, elementContainer);

        if(!element.noContent && element.type !== ELEMENT_TYPE.DIVIDER && !element.value && R.isEmpty(element.elements || [])) containerAttributes["no-content"] = `EMPTY ${element.type}`;

        containerAttributes["data-for-page"] = this.props.page._id;

        const attributes = R.mergeAll(R.map(x => ({[x.name]: x.value || ""}), element.attributes || []));
        containerAttributes = R.mergeLeft(containerAttributes, attributes);
        return containerAttributes;
    };

    hasErrorsForField = (element) => {
        return (this.state.clientErrors && this.state.clientErrors[element.key]) || (getValueForPathOrDefault(this.state, "errors.fieldErrors", {})[element.key]);
    };

    getErrorsForField = (element) => {
        const elementErrors = element.errors || {};
        const clientErrors = [];
        if(this.state.clientErrors && this.state.clientErrors[element.key]) {
            const errors = Array.isArray(this.state.clientErrors[element.key]) ? this.state.clientErrors[element.key] : [this.state.clientErrors[element.key]];
            R.forEach((e) => {
                const code = e.code || e;
                clientErrors.push({code, description: elementErrors[code] || e.description || elementErrors["INVALID_FORMAT"] || `Please provide valid ${element.label}`});
            }, errors);
        }

        const fieldErrors = getValueForPathOrDefault(this.state, "errors.fieldErrors", {})[element.key] || [];

        return R.map(this.getErrorView, R.concat(clientErrors, fieldErrors));
    };

    onFilesChanged = () => this.setState({}, true);

    getValueForElement = (element, elementPath) => {
        if(element.value && element.parser) {
            if(R.find(p => p.key === "value" && p.type === PARSER_TYPE.JSON_PATH, element.parser)) {
                return `\${${element.value}}`;
            }
        }
        // if(element.formatter && element.formatter.type === VALUE_FORMATTER.DATE) {
        //     return formatDateTime(element.value, element.formatter.format);
        // }
        return element.value || "";
    };

    getSelectedFileNamesForElement = (elementPath) => {
        const $el = this.$container.find(`[data-elem-path="${elementPath}"]`);
        const element = $el[0];
        if(element && element.files && element.files.length > 0) {
            let names = [];
            for(let index = 0; index < element.files.length ; index++) {
                names.push(element.files[index].name);
            }
            return names;
        }
        return [];
    };

    placeholderForElement = (element) => element.placeholder || "";

    postRenderView() {
        return super.postRenderView()
            .then(this.renderUI)
            .then(this.renderCharts)
            .then(this.applyAutoFocus)
            .then(this.renderSubPages);
    }

    runAggregatorTransformer = (element, transformer, value) => {
        const type = transformer.type;
        switch(type) {
            case AGGREGATOR_TRANSFORMER.MINUTE:
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.tooltipFormat", "MM/DD/YYYY HH:mm");
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.unit", "minute");
                return getDateStringWithFormatForTimeInMillis(value, 'MM/DD/YYYY HH:mm[:01]');
            case AGGREGATOR_TRANSFORMER.HOUR:
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.tooltipFormat", "MM/DD/YYYY HH");
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.unit", "hour");
                return getDateStringWithFormatForTimeInMillis(value, 'MM/DD/YYYY HH[:00:01]');
            case AGGREGATOR_TRANSFORMER.WEEK:
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.tooltipFormat", "MM/DD/YYYY HH:mm");
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.unit", "week");
                return getDateStringWithFormatForTimeInMillis(moment(value).startOf('isoWeek'), 'MM/DD/YYYY HH:mm[:01]');
            case AGGREGATOR_TRANSFORMER.MONTH:
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.tooltipFormat", "MM/YYYY");
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.unit", "month");
                return getDateStringWithFormatForTimeInMillis(value, 'MM[/01]/YYYY [00:00:01]');
            case AGGREGATOR_TRANSFORMER.YEAR:
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.tooltipFormat", "YYYY");
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.unit", "year");
                return getDateStringWithFormatForTimeInMillis(value, '[01/01/]YYYY [00:00:01]');
            default:
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.tooltipFormat", "MM/DD/YYYY");
                setValueIfEmptyForPathOrDefault(element, "options.scales.x.time.unit", "day");
                return getDateStringWithFormatForTimeInMillis(value, 'MM/DD/YYYY [00:00:01]');
        }
    };

    runAggregator = (aggregator, values) => {
        const type = aggregator.type;
        switch(type) {
            case AGGREGATOR_TYPE.SUM:
                return R.sum(values);
            case AGGREGATOR_TYPE.AVERAGE:
                return R.sum(values)/R.length(values);
            case AGGREGATOR_TYPE.MINIMUM:
                return R.reduce(R.min, Number.MAX_VALUE, values)
            case AGGREGATOR_TYPE.MAXIMUM:
                return R.reduce(R.max, Number.MIN_VALUE, values)
            default:
                return R.length(values);
        }
    };

    transformChartData = (element) => {
        if(element.datasets) {
            return R.map((ds) => {
                const data = ds.data;
                ds.data = mapIndexed((value, index) => {
                    if(value && value.x) return value;
                    else return {x: element.labels[index], y: value};
                }, data);
                return ds;
            }, element.datasets);
        }
        return [];
    };

    processChartAggregator = (element) => {

        const enabled = getValueForPathOrDefault(element, "aggregator.enabled", false);
        if(!enabled) {
            return {
                labels: element.labels,
                datasets: element.datasets
            };
        }

        const transformer = getValueForPathOrDefault(element, "aggregator.transformer", {});
        const aggregator = getValueForPathOrDefault(element, "aggregator", {});

        const datasets = R.map(ds => {

            const data = ds.data;
	
            const map = R.indexBy(r => r.x, data);
        
            const oldToNewX = R.reduce((acc, x) => {
                
                const newX = this.runAggregatorTransformer(element, transformer, x);
                
                const list = acc.newMap[newX] || (acc.newMap[newX] = []);
                list.push(map[x].y);
        
                acc.xMap[x] = newX;
        
                return acc;
        
            }, {xMap: {}, newMap: {}}, R.map(R.prop('x'), data));
        
            const calculated = {};

            ds.data = R.filter(x => x, R.map(r => {
                const newX = oldToNewX.xMap[r.x];
                
                if(calculated[newX]) return null;

                calculated[newX] = true;
                
                const values = oldToNewX.newMap[newX];
            
                return {x: newX, y: this.runAggregator(aggregator, values)};
            }, data));

            return ds;
        
        }, this.transformChartData(element));

        return {
            // labels: element.labels,
            datasets
        };
    };

    renderChartForDOMElement = (index, el) => {
        const id = el.getAttribute('id');
        let element = this.getElementById(id);
        if(element) {
            try {
                element = this.compileElement(R.clone(element), this.getCompiledData());
                const ctx = el.getContext('2d');
                const chart = this.charts[id];

                const data = this.processChartAggregator(element);
                // {
                //     labels: element.labels,
                //     datasets: element.datasets
                // };

                let options = element.options;

                if (element.stacked) {
                    options = R.mergeDeepLeft({
                        scales: {
                            x: {
                                stacked: true
                            },
                            y: {
                                stacked: true
                            }
                        }
                    }, element.options);
                }

                if(getValueForPathOrDefault(options, "scales.x.type") === SCALE_TYPE.TIME && data.datasets) {
                    R.forEach(ds => {
                        ds.data = Array.isArray(ds.data) ? ds.data : [{x: new Date(), y: 10}, {x: new Date(), y: 12}];
                    }, data.datasets);
                    data.labels = data.labels || [new Date(), new Date()];
                }

                if(options.aspectRatio) {
                    options.aspectRatio = parseInt(options.aspectRatio);
                }

                if(chart) {
                    window.chart = chart;
                    chart.data = data;
                    chart.options = options;
                    chart.update();
                    chart.resize();
                } else {
                    this.charts[id] = new window.Chart(ctx, {
                        type: CHART_TYPE[element.type],
                        data,
                        options
                    });
                }
            } catch(err) {}
        }
    };

    renderCharts = () => {
        return this.$container.find(`[data-for-page="${this.props.page._id}"] > canvas`).each(this.renderChartForDOMElement);
    };

    renderUI = () => {
        this.$container.find('.checkbox').checkbox();
        this.$container.find('.radio').checkbox();
        this.renderDropDowns(this.$container);
        return this.renderDatePicker();
    };

    renderDatePicker = () => {
        const $pickers = this.$container.find(`[data-element-type="${ELEMENT_TYPE.DATETIME_PICKER}"]`);
        const self = this;
        $pickers.each((index, el) => {
            const id = el.getAttribute('id');
            const element = self.getElementById(id);
            if(element) {
                const options = element.initOptions || {};
                if (options.initialDate) {
                    options.initialDate = new Date(options.initialDate);
                }
                if (options.minDate) {
                    options.minDate = new Date(options.minDate);
                }
                if (options.maxDate) {
                    options.maxDate = new Date(options.maxDate);
                }
                if(options.disabledDates) {
                    options.disabledDates = R.map((disabledDate) => {
                        if(disabledDate.date) {
                            disabledDate.date = R.map((date) => new Date(date), R.flatten([disabledDate.date]));
                        }
                        return disabledDate;
                    }, options.disabledDates)
                }
                if(options.enabledDates) {
                    options.enabledDates = R.map((enabledDate) => {
                        return new Date(enabledDate);
                    }, options.enabledDates)
                }
                if(options.eventDates) {
                    options.eventDates = R.map((eventDate) => {
                        if(eventDate.date) {
                            eventDate.date = R.map((date) => new Date(date), R.flatten([eventDate.date]));
                        }
                        return eventDate;
                    }, options.eventDates)
                }
                if(element.dateFormat) {
                   options.formatter = {
                    datetime: function(date, settings) {
                      if(!date) return "";
                      return formatDateWithFormat(date, element.dateFormat)
                      }
                  }
                }
                $(el).calendar(options);
            }
        });
    };

    renderSubPages = () => {

        if(!this.state.dataLoaded) return;

        let layout = this.getLayout();

        if(!layout) return;

        const data = this.state.compiledData || {};

        layout = compileLayoutWithParser(layout, data);

        const subPageContainers = this.$container.find('[data-sub-page]');
        return R.reduce((acc, container) => {
            return acc.then(() => {
                const containerId = container.getAttribute("id");
                const elementPath = container.getAttribute("data-elem-path");
                const element = getValueForPathOrDefault(layout, elementPath);
                if(!element || !element.value || !element.value.id) return Promise.resolve();

                const elemProps = getValueForPathOrDefault(element, "value.props", {});
                const page = R.clone(this.props.getPageById(element.value.id));
                if(!page) return Promise.resolve();

                let props = {
                    pageProps: R.mergeLeft(elemProps, this.props.pageProps),
                    page,
                    subPage: true
                };
                props = R.mergeLeft(props, this.props);
                const alreadyRendered = this.subPages[containerId];
                const subPage = this.subPages[containerId] || new DynamicComponentView(container, props, false, containerId);
                this.subPages[containerId] = subPage;
                return alreadyRendered ? subPage.updateProps(props) : subPage.render();
            });
        }, Promise.resolve(), subPageContainers);
    };

    applyAutoFocus = () => this.$container.find('[autofocus]').focus();

    getDropdownViewForTuple = ({dropdownList, elementPath, selectedValue, inputDataKey, onChangeHandler, containerId, multiSelect, classes, fieldRequired, validationType, validationError, dataType}) => {
        const labelValueList = R.map(lv => ({label: lv[0], value: lv[1]}), dropdownList || []);
        return this.getDropdownViewForLabelValue({dropdownList: labelValueList, elementPath, selectedValue, inputDataKey, onChangeHandler, containerId, multiSelect, classes, fieldRequired, validationType, validationError, dataType});
    };

    getDropdownViewForLabelValue = ({dropdownList, elementPath, selectedValue, inputDataKey, onChangeHandler, containerId, multiSelect = false, classes = "", fieldRequired = false, validationType = VALIDATION_TYPE.NON_EMPTY, validationError = '', dataType = ''}) => {
        const values = R.map(lv => lv.value, dropdownList);

        const $existingEl = this.$container.find(`[data-key="${inputDataKey}"]`);
        if($existingEl.length > 0) {
            selectedValue = $existingEl.val();
            selectedValue = multiSelect ? selectedValue.split(",") : selectedValue;
        }

        selectedValue = multiSelect ? R.intersection(selectedValue || [], values) : (R.includes(selectedValue, values) ? selectedValue : "");
        if(onChangeHandler) this.ddOnChangeHandlers = R.assoc(inputDataKey, onChangeHandler, this.ddOnChangeHandlers || {});
        let views = R.map(labelValue => {
            const selected = multiSelect ? R.includes(labelValue.value, selectedValue) : selectedValue === labelValue.value;
            const claz = selected ? (multiSelect ? "active filtered" : "active selected") : "";
            return (
                <div className={"item " + claz} data-value={labelValue.value}>{labelValue.label}</div>
            );
        }, dropdownList);

        const className = (multiSelect ? "ui search fluid dropdown selection multiple" : "ui search selection dropdown") + " " + (classes || "") + (validationError? " fm-val-error": "");

        const selectedInputValue = multiSelect ? R.join(",", selectedValue || []) : selectedValue;

        // //console.log("selectedInputValue: " + selectedInputValue);

        let multiSelectViews = multiSelect ? R.map(v => {
            const label = R.find(lv => lv.value === v, dropdownList).label;
            return (
                <a className="ui label transition visible" data-value={v}>
                    {label}<i className="delete icon"/>
                </a>
            );
        }, selectedValue) : null;

        if(R.isEmpty(multiSelectViews)) multiSelectViews = null;

        const selectedInputLabel = multiSelect ? "" : (selectedInputValue || "Select");

        dataType = dataType || (multiSelect ? DATA_TYPE.ARRAY: "");

        return (
            <div key={Date.now()} id={containerId} data-elem-path={elementPath} data-dd-controller={inputDataKey} className={className} tabIndex={0}>
                <input id={"dd-input-" + inputDataKey} data-validation-type={validationType} data-required={fieldRequired} type="hidden" data-key={inputDataKey} data-type={dataType} value={selectedInputValue} data-multi-value={multiSelect}/>
                <i className="dropdown icon" />
                {multiSelectViews}
                <div className="text">{selectedInputLabel}</div>
                <div className="menu transition hidden" tabIndex={-1}>
                    {views}
                </div>
            </div>
        )
    };

    getDropdownViewForValues = ({dropdownList, elementPath, selectedValue, inputDataKey, onChangeHandler, containerId, multiSelect, classes, fieldRequired, validationType, validationError, dataType}) => {
        const labelValueList = R.map(value => ({label: value, value: value}), dropdownList || []);
        return this.getDropdownViewForLabelValue({dropdownList: labelValueList, elementPath, selectedValue, inputDataKey, onChangeHandler, containerId, multiSelect, classes, fieldRequired, validationType, validationError, dataType});
    };

    updateProps = (props) => {
        if(R.equals(props.pageProps, this.props.pageProps)) return this.renderView();
        this.destroyCharts();
        this.props = props;
        return this.render();
    };

    destroyCharts = () => {
        R.values(this.charts).map(c => c.destroy());
        this.charts = {};
    };

    destroy() {
        this.destroyCharts();

        R.values(this.subPages).map(p => p.destroy());
        this.subPages = {};

        const styleSelector = `#page-${this.props.page._id}`;
        $(styleSelector).remove();

        return super.destroy();
    }

    renderDropDowns = (container) => {
        const _self = this;
        //console.log("rendering dds");
        const onChange = (value, label, $selectedItem) => {
            if (!($selectedItem instanceof $)) {
                value = label;
                label = $selectedItem;
                $selectedItem = $(this).find(`.menu .item[data-value="${value}"]`);
            }
            //console.log("onchange on dropdown");
            //console.log($selectedItem);
            const controller = getAttrValueFromClosest($selectedItem, 'data-dd-controller');
            //console.log("controller: " + controller);

            if (controller && _self.ddOnChangeHandlers && _self.ddOnChangeHandlers[controller]) {
                // _self.ddOnChangeHandlers[controller](value, label, $selectedItem, $(this));
                // _self.ddOnChangeHandlers[controller]($(this), value, label, $selectedItem);
                const $el = getClosestForElementWithAttribute($selectedItem, 'data-dd-controller');
                const el = $el[0];
                
                const event = new Event("customEvent");
                const handler = _self.ddOnChangeHandlers[controller];
                el.addEventListener(
                    "customEvent",
                    handler,
                    false
                  );
                el.dispatchEvent(event);
                el.removeEventListener("customEvent", handler, false);
                // _self.ddOnChangeHandlers[controller](new CustomEvent('build', { target: $(this)[0] }));
            }
        };
        return $(container).find('.ui.dropdown').each((index, el) => {
            const $el = $(el);
            const controller = $el.attr('data-dd-controller');

            const initData = this.ddInitData && this.ddInitData[controller];
            if (initData && initData.selectedValue) {
                R.forEach((value) => $el.dropdown("set selected", value), R.flatten([initData.selectedValue || []]));
            }
            $el.dropdown({ match: "text", onChange });
        });
    };
}